<template>
  <div class="ai-advantage">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
    <div class="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "ai-advantage",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.ai-advantage {
  width: 380px;
  height: 320px;
  background-color: white;
  box-shadow: 0 0 40px 0 #eef3ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image {
    margin-bottom: 35px;
  }
  #price {
    width: 60px;
    height: 60px;
    @include bg-image("../../../assets/solutions/Ai/price");
  }
  #quick {
    width: 76px;
    height: 56px;
    margin: 2px 0 37px 0;
    @include bg-image("../../../assets/solutions/Ai/quick");
  }
  #performance {
    width: 60px;
    height: 50px;
    margin: 5px 0 40px 0;
    @include bg-image("../../../assets/solutions/Ai/performance");
  }
  .title {
    color: #182940;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 27px;
  }
  .content {
    text-align: center;
    color: #5e7290;
    font-weight: 400;
    font-size: 16px;
    width: 320px;
    line-height: 24px;
  }
}
.ai-advantage:hover {
  box-shadow: 0 0 40px 0 #e0e4f0;
}
</style>