<template>
  <div class="raysync-function">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
    <div class="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "raysync-function",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.raysync-function {
  width: 305px;
  margin-bottom: 54px;
  .image {
    width: 305px;
    height: 259px;
    margin-bottom: 16px;
  }
  #file {
    @include bg-image("../../../assets/products/Raysync/file");
  }
  #account {
    @include bg-image("../../../assets/products/Raysync/account");
  }
  #group {
    @include bg-image("../../../assets/products/Raysync/group");
  }
  #synchronization {
    @include bg-image("../../../assets/products/Raysync/synchronization");
  }
  #management {
    @include bg-image("../../../assets/products/Raysync/management");
  }
  #storage {
    @include bg-image("../../../assets/products/Raysync/storage");
  }
  #personal {
    @include bg-image("../../../assets/products/Raysync/personal");
  }
  #multiple {
    height: 210px;
    margin: 31px 0 34px 0;
    /* @include bg-image("../../../assets/products/Raysync/multiple"); */
    background-image: url("../../../assets/products/Raysync/multiple2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .title {
    margin-bottom: 23px;
    color: #182940;
    font-size: 24px;
    font-weight: 400;
  }
  .content {
    color: #8796aa;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }
}
</style>