<template>
  <div class="game">
    <top-bar active="solutions" theme="white"></top-bar>
    <solutions-banner image="game" content="电竞级帧率·影院级画质·丰富算力">
      面向云游戏平台提供<br />
      专属算力资源
    </solutions-banner>
    <div class="sub-content-list">
      <sub-content
        title="方案优势"
        subtitle="云游戏是以云计算为基础的游戏方式，在云游戏的运行模式下，所有游戏都在服务器端运行，游戏画面渲染完成、压缩后，通过网络传送给用户；GPUCAT为云游戏场景提供低成本、优质稳定的云游戏算力和解决方案，助力云游戏在5G时代下快速发展！"
        bgcolor="#F8FAFF"
      >
        <div class="game-advantage-list">
          <game-advantage
            image="computing"
            title="算力优势"
            content="拥有丰富云游戏场景的算力资源，云游戏就近部署，就近分配。"
          >
          </game-advantage>
          <game-advantage
            image="professional"
            title="专业解决方案"
            content="拥有云游戏运营经验，帮助客户打造全场景云游戏运营解决方案。"
          >
          </game-advantage>
          <game-advantage
            image="good"
            title="优质体验"
            content="可达电竞级帧率(120FPS)，影院级画质(4K)，4G网络下也能畅通无阻。"
          >
          </game-advantage>
          <game-advantage
            image="cross"
            title="跨平台"
            content="多种终端(电脑、手机、平板、盒子)，多系统兼容，多种设备支持。"
          >
          </game-advantage>
        </div>
      </sub-content>
      <sub-content title="架构部署">
        <div class="structure-wrap">
          <div id="structure-image"></div>
          <div class="point-wrap">
            <point
              image="stable"
              title="稳定可靠"
              content="底层基于KVM和容器技术，采用自研调度系统，稳定运行"
            ></point>
            <point
              image="convenient"
              title="便捷管理"
              content="拥有计费、游戏管理、节点调度、连接鉴权等系统"
            ></point>
            <point
              image="super"
              title="超强算力"
              content="GPU+CPU异构计算，畅快运行各大主机级游戏"
            ></point>
          </div>
        </div>
      </sub-content>
      <sub-content title="应用场景" bgcolor="#F8FAFF">
        <div class="scene-list">
          <scene
            image="game"
            title="云游戏"
            content="无需用户配置高性能电脑，多终端支持随时随地玩各类游戏大作。"
          ></scene>
          <scene
            image="play"
            title="云试玩"
            content="广告位、信息流、应用市场等各种场景，无需下载即可试玩，提升转化率。"
          ></scene>
          <scene
            image="live"
            title="直播互动"
            content="直播场景的新玩法，粉丝观看直播，随时就能与主播进行游戏对战。"
          ></scene>
          <scene
            image="tv"
            title="大屏电视"
            content="电视盒子应用的新功能，普通的电视盒子也能畅玩主机游戏。"
          ></scene>
        </div>
      </sub-content>
    </div>
    <contact-us></contact-us>
    <bottom-info></bottom-info>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import SolutionsBanner from "../components/SolutionsBanner.vue";
import SubContent from "../components/SubContent.vue";
import GameAdvantage from "./components/GameAdvantage.vue";
import Point from "./components/Point.vue";
import Scene from "./components/Scene.vue";
import ContactUs from "../components/ContactUs.vue";
import BottomInfo from "../components/BottomInfo.vue";
export default {
  name: "game",
  components: {
    TopBar,
    SolutionsBanner,
    SubContent,
    GameAdvantage,
    Point,
    Scene,
    ContactUs,
    BottomInfo,
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.game {
  .sub-content-list {
    .game-advantage-list {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 100px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
    .structure-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 120px;
      #structure-image {
        width: 780px;
        height: 412px;
        @include bg-image("../../assets/solutions/Game/structure");
      }
      .point-wrap {
        margin-left: 63px;
      }
    }
    .scene-list {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin-bottom: 97px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
  }
}
</style>