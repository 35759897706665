import Index from "./pages/Index.vue";

import Network from "./pages/products/Network.vue";
import Desktop from "./pages/products/Desktop.vue";
import Server from "./pages/products/Server.vue";
import Raysync from "./pages/products/Raysync.vue";
import CDN from "./pages/products/CDN.vue";

import CtsGmsNts from "./pages/solutions/CtsGmsNts.vue";
import Game from "./pages/solutions/Game.vue";
import Ai from "./pages/solutions/Ai.vue";
import Education from "./pages/solutions/Education.vue";
import Streaming from "./pages/solutions/Streaming.vue";
import RenderCloud from "./pages/solutions/RenderCloud.vue";

import About from "./pages/About.vue";

import News from "./pages/news/News.vue";
import NewsList from "./pages/news/components/NewsList.vue";
import NewsDetail from "./pages/news/components/NewsDetail.vue";

export const routes = [
  { path: "/", component: Index },

  { path: "/products/network", component: Network },
  { path: "/products/desktop", component: Desktop },
  { path: "/products/server", component: Server },
  { path: "/products/fastsync", component: Raysync },
  { path: "/products/cdn", component: CDN },

  { path: "/solutions/ctsgmsnts", component: CtsGmsNts },
  { path: "/solutions/game", component: Game },
  { path: "/solutions/ai", component: Ai },
  { path: "/solutions/education", component: Education },
  { path: "/solutions/streaming", component: Streaming },
  { path: "/solutions/rendercloud", component: RenderCloud },

  { path: "/about", component: About },

  {
    path: "/news",
    component: News,
    children: [
      { path: "list", component: NewsList, props: true },
      { path: "detail/:id", component: NewsDetail },
    ],
  },
];
