<template>
  <div class="scene">
    <div class="image" :id="image">
      <div class="title">{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "scene",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.scene {
  margin-bottom: 18px;
  .image {
    width: 388px;
    height: 216px;
    line-height: 216px;
    text-align: center;
    @media screen and (max-width: 1200px) {
      width: 168.5px;
      height: 94px;
      display: flex;
      justify-content: center;
      align-items: bottom;
    }
    .title {
      color: white;
      font-size: 24px;
      font-weight: 400;
      @media screen and (max-width: 1200px) {
        font-size: 15px;
        line-height: 94px;
      }
    }
  }
  #store {
    @include bg-image("../../../assets/products/Network/store");
  }
  #remote {
    @include bg-image("../../../assets/products/Network/remote");
  }
  #connection {
    @include bg-image("../../../assets/products/Network/connection");
  }
  #monitor {
    @include bg-image("../../../assets/products/Network/monitor");
  }
  #meeting {
    @include bg-image("../../../assets/products/Network/meeting");
  }
  #design {
    @include bg-image("../../../assets/products/Server/design");
  }
  #game {
    @include bg-image("../../../assets/products/Server/game");
  }
  #movie {
    @include bg-image("../../../assets/products/Server/movie");
  }
  #desktop {
    @include bg-image("../../../assets/products/Server/desktop");
  }
  #ai {
    @include bg-image("../../../assets/products/Server/ai");
  }
}
</style>