<template>
  <div class="desktop-scene">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
    <div class="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "desktop-scene",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.desktop-scene {
  width: 280px;
  height: 300px;
  background-color: white;
  box-shadow: 0 0 30px 0 #eef3ff;
  @media screen and (max-width: 1200px) {
    width: calc(50% - 4px);
    height: 160px;
    margin-bottom: 7.5px;
  }
  .image {
    margin-top: 44px;
    margin-left: 28px;
    @media screen and (max-width: 1200px) {
      margin-top: 22px;
      margin-left: 14px;
    }
  }
  #office {
    width: 60px;
    height: 55px;
    margin-bottom: 6px;
    @include bg-image("../../../assets/products/Desktop/office");
    @media screen and (max-width: 1200px) {
      width: 30px;
      height: 27.5px;
      margin-bottom: 3px;
    }
  }
  #design {
    width: 56px;
    height: 56px;
    margin-bottom: 5px;
    @include bg-image("../../../assets/products/Desktop/design");
    @media screen and (max-width: 1200px) {
      width: 28px;
      height: 28px;
      margin-bottom: 2px;
    }
  }
  #video {
    width: 56px;
    height: 61px;
    @include bg-image("../../../assets/products/Desktop/video");
    @media screen and (max-width: 1200px) {
      width: 28px;
      height: 30.5px;
    }
  }
  #education {
    width: 73px;
    height: 55px;
    margin-bottom: 6px;
    @include bg-image("../../../assets/products/Desktop/education");
    @media screen and (max-width: 1200px) {
      width: 36.5px;
      height: 27.5px;
      margin-bottom: 3px;
    }
  }
  .title {
    margin-left: 28px;
    padding-top: 35px;
    color: #15224a;
    font-size: 20px;
    font-weight: 400;
    @media screen and (max-width: 1200px) {
      margin-left: 14.5px;
      padding-top: 13px;
      font-size: 15px;
    }
  }
  .content {
    margin-left: 28px;
    width: 215px;
    margin-top: 16px;
    color: #5e7290;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    @media screen and (max-width: 1200px) {
      margin-left: 14.5px;
      margin-top: 5.5px;
      width: 100%;
      font-size: 10px;
      transform: scale(0.83);
      transform-origin: 0 0;
      line-height: 18px;
    }
  }
}
.desktop-scene:hover {
  box-shadow: 0 0 30px 0 #e3e8f3;
}
</style>