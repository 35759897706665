<template>
  <div :class="{ banner: true, bg: !!bg }">
    <div class="banner-wrap">
      <div class="text" data-aos="fade-up" data-aos-duration="500">
        <div class="title">{{ title }}</div>
        <div class="content">{{ content }}</div>
      </div>
      <div class="image" :id="image" v-if="image"></div>
      <video
        :src="video"
        controls="controls"
        v-if="video"
        width="600"
        height="340"
      ></video>
    </div>
  </div>
</template>

<script>
export default {
  name: "banner",
  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    image: {
      type: String,
    },
    video: {
      type: String,
    },
    bg: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.banner {
  height: 540px;
  background-color: #07235f;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1200px) {
    height: auto;
    padding: 26.5px 0;
  }
  .banner-wrap {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1200px) {
      max-width: 100%;
    }
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
    .text {
      color: white;
      margin-right: 181px;
      @media screen and (max-width: 1200px) {
        margin-right: 0;
      }
      .title {
        font-weight: bold;
        font-size: 48px;
        @media screen and (max-width: 1200px) {
          font-size: 24px;
          text-align: center;
        }
      }
      .content {
        font-weight: 400;
        font-size: 16px;
        margin-top: 39px;
        width: 424px;
        line-height: 26px;
        @media screen and (max-width: 1200px) {
          text-align: center;
          margin: 24px auto 23.5px auto;
          width: 86.1%;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .image {
    }
    #network {
      width: 584px;
      height: 452px;
      @include bg-image("../../assets/products/Network/banner");
      @media screen and (max-width: 1200px) {
        width: 85%;
        height: 0;
        padding-bottom: 65.7%;
        margin-top: -20px;
        margin-bottom: 20px;
      }
    }
    #desktop {
      width: 661px;
      height: 435px;
      @include bg-image("../../assets/products/Desktop/banner");
      @media screen and (max-width: 1200px) {
        margin-top: 20px;
        width: 88%;
        height: 0;
        padding-bottom: 58%;
      }
    }
    #server {
      width: 525px;
      height: 460px;
      @include bg-image("../../assets/products/Server/banner");
      @media screen and (max-width: 1200px) {
        width: 70%;
        height: 0;
        padding-bottom: 61.3%;
      }
    }
    #cdn {
      width: 540px;
      height: 480px;
      @include bg-image("../../assets/products/CDN/banner");
      @media screen and (max-width: 1200px) {
        width: 70%;
        height: 0;
        padding-bottom: 61.3%;
      }
    }
  }
}
.bg {
  background-image: url("../../assets/products/Raysync/banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>