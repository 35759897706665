<template>
  <div class="desktop-advantage">
    <div class="image" :id="image"></div>
    <div class="text">
      <div class="title">{{ title }}</div>
      <div class="content">{{ content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "desktop-advantage",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.desktop-advantage {
  background-color: white;
  width: 590px;
  height: 130px;
  display: flex;
  box-shadow: 0 0 30px 0 #eef3ff;
  margin-bottom: 20px;
  @media screen and (max-width: 1200px) {
    max-width: 100%;
    height: 90px;
    box-shadow: 0 0 15px 0 #eef3ff;
    margin-bottom: 4.5px;
  }
  .image {
    margin-top: 29px;
    margin-left: 28px;
    @media screen and (max-width: 1200px) {
      flex-shrink: 0;
      margin-top: 23.5px;
      margin-left: 19px;
    }
  }
  #product-safety {
    width: 44px;
    height: 48px;
    @include bg-image("../../../assets/products/Desktop/productSafety");
    @media screen and (max-width: 1200px) {
      width: 27.5px;
      height: 30.5px;
    }
  }
  #center {
    width: 46px;
    height: 46px;
    @include bg-image("../../../assets/products/Desktop/center");
    @media screen and (max-width: 1200px) {
      width: 29.5px;
      height: 29.5px;
    }
  }
  #danger {
    width: 46px;
    height: 46px;
    @include bg-image("../../../assets/products/Desktop/danger");
    @media screen and (max-width: 1200px) {
      width: 29.5px;
      height: 29.5px;
    }
  }
  #remote {
    width: 48px;
    height: 45px;
    @include bg-image("../../../assets/products/Desktop/remote");
    @media screen and (max-width: 1200px) {
      width: 30.5px;
      height: 29px;
    }
  }
  #usb {
    width: 48px;
    height: 48px;
    @include bg-image("../../../assets/products/Desktop/usb");
    @media screen and (max-width: 1200px) {
      width: 30.5px;
      height: 30.5px;
    }
  }
  #performance {
    width: 44px;
    height: 48px;
    @include bg-image("../../../assets/products/Desktop/performance");
    @media screen and (max-width: 1200px) {
      width: 27.5px;
      height: 30.5px;
    }
  }
  #green {
    width: 48px;
    height: 48px;
    @include bg-image("../../../assets/products/Desktop/green");
    @media screen and (max-width: 1200px) {
      width: 30.5px;
      height: 30.5px;
    }
  }
  #life-cycle {
    width: 48px;
    height: 48px;
    @include bg-image("../../../assets/products/Desktop/lifeCycle");
    @media screen and (max-width: 1200px) {
      width: 30.5px;
      height: 30.5px;
    }
  }
  .text {
    margin-top: 22px;
    margin-left: 28px;
    @media screen and (max-width: 1200px) {
      margin-top: 15.5px;
      margin-left: 18px;
    }
    .title {
      color: #15224a;
      font-size: 20px;
      font-weight: 400;
      @media screen and (max-width: 1200px) {
        font-size: 15px;
      }
    }
    .content {
      line-height: 21px;
      width: 439px;
      margin-top: 16px;
      color: #5e7290;
      font-size: 14px;
      font-weight: 400;
      @media screen and (max-width: 1200px) {
        width: 120.4%;
        font-size: 10px;
        transform: scale(0.83);
        transform-origin: 0 0;
        line-height: 20px;
        margin-top: 8px;
      }
    }
  }
}
.desktop-advantage:hover {
  box-shadow: 0 0 30px 0 #e1e6f1;
}
</style>