<template>
  <div class="scene">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
    <div class="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "scene",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.scene {
  width: 280px;
  height: 300px;
  background: white;
  box-shadow: 0 0 30px 0 #eef3ff;
  .image {
    margin: 53px 0 33px 28px;
  }
  #game {
    width: 60px;
    height: 60px;
    @include bg-image("../../../assets/solutions/Game/game");
  }
  #play {
    width: 43px;
    height: 60px;
    @include bg-image("../../../assets/solutions/Game/play");
  }
  #live {
    width: 66px;
    height: 60px;
    @include bg-image("../../../assets/solutions/Game/live");
  }
  #tv {
    width: 74px;
    height: 60px;
    @include bg-image("../../../assets/solutions/Game/tv");
  }
  .title {
    margin-left: 28px;
    color: #15224a;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 25px;
  }
  .content {
    margin-left: 28px;
    color: #5e7290;
    font-weight: 400;
    font-size: 14px;
    width: 210px;
    line-height: 24px;
  }
}
.scene:hover {
  box-shadow: 0 0 30px 0 #e1e6f1;
}
</style>