<template>
  <div class="render-advantage">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
    <div class="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "render-advantage",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.render-advantage {
  width: 280px;
  height: 360px;
  background: white;
  box-shadow: 0 0 30px 0 #eef3ff;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .image {
    margin-top: 47px;
    width: 66px;
    height: 66px;
  }
  #high-performance {
    @include bg-image("../../../assets/solutions/RenderCloud/highperformance");
  }
  #safe {
    @include bg-image("../../../assets/solutions/RenderCloud/safe");
  }
  #unify {
    @include bg-image("../../../assets/solutions/RenderCloud/unify");
  }
  #price {
    @include bg-image("../../../assets/solutions/RenderCloud/price");
  }
  .title {
    color: #15224a;
    font-weight: 400;
    font-size: 24px;
    margin-top: 21px;
    margin-bottom: 23px;
  }
  .content {
    color: #5e7290;
    font-weight: 400;
    font-size: 14px;
    width: 224px;
    line-height: 24px;
  }
}
.render-advantage:hover {
  box-shadow: 0 0 30px 0 #e1e6f1;
}
</style>