<template>
  <div class="contact-us-wrap">
    <div class="contact-us" data-aos="fade-up" data-aos-duration="500">
      <div class="title">最贴近企业用户的云服务解决方案商</div>
      <div class="button" @click="$router.push('/about')">联系我们</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact-us",
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.contact-us-wrap {
  background-color: #145cf6;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 812px) {
    height: 130px;
  }
}
.contact-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: 260px;
  width: 100%;
  @include bg-image-raw("../../assets/Index/contact-us");
  @media screen and (max-width: 812px) {
    height: 130px;
  }
  @media screen and (max-width: 500px) {
    height: 65px;
  }
  .title {
    font-size: 48px;
    margin-bottom: 40px;
    @media screen and (max-width: 812px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .button {
    cursor: pointer;
    width: 200px;
    height: 46px;
    border: 2px solid white;
    line-height: 46px;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    @media screen and (max-width: 812px) {
      width: 130px;
      height: 34px;
      line-height: 34px;
      font-size: 15px;
      border: 1px solid white;
    }
  }
  .button:hover {
    background-color: white;
    color: #145cf6;
  }
}
</style>