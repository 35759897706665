export const GetPrevAndNextNewsTitle = `query GetPrevAndNextNewsTitle($currentNewsId: ID!) {
  previousNews: newslist(
    sort: "publishedAt:ASC"
    pagination: { limit: 1 }
    filters: { id: { gt: $currentNewsId } }
  ) {
    data {
      id
      attributes {
        title
      }
    }
  }
  nextNews: newslist(
    sort: "publishedAt:DESC"
    pagination: { limit: 1 }
    filters: { id: { lt: $currentNewsId } }
  ) {
    data {
      id
      attributes {
        title
      }
    }
  }
}`;
