<template>
  <div class="cdn">
    <top-bar active="products"></top-bar>
    <banner
      image="cdn"
      title="CDN"
      content="国信CDN是一种先进的内容分发网络服务，专注于解决跨地域、跨运营商的网络性能问题。通过全球覆盖的加速节点，我们提供稳定、快速的网络访问服务，同时支持音视频点播、大文件下载及Web加速服务。"
    ></banner>
    <div class="sub-content-list">
      <sub-content title="产品优势">
        <div class="cdn-advantage-list">
          <cdn-advantage
            image="good"
            title="全球优质资源"
            content="2800+全球节点，70多个国家与地区覆盖，超100+运营商支持，为各类型业务提供高速、稳定的业务访问体验"
          ></cdn-advantage>
          <cdn-advantage
            image="easy"
            title="接入快捷，简单易用"
            content="接入方式简单快速，提供自助化配置管理，支持多种可定制配置项，方便客户进行统计分析、日志管理、自定义缓存策略"
          ></cdn-advantage>
          <cdn-advantage
            image="safe"
            title="安全可靠"
            content="国信CDN为客户提供中立、安全、可靠的CDN云服务。支持全链路HTTPS安全传输，网站防盗链等高级安全控制功能%以上软件版权成本"
          ></cdn-advantage>
          <cdn-advantage
            image="intelligence"
            title="智能调度，稳定极速"
            content="支持多维度调度策略，配合精准的带宽预测，实现跨运营商、跨地域、最小粒度的实时动态资源调度"
          ></cdn-advantage>
        </div>
      </sub-content>
      <sub-content title="应用场景" bgcolor="#F8FAFF">
        <div class="cdn-feature-wrap">
          <div class="cdn-feature-list">
            <cdn-feature
              title="网站加速"
              :active="0"
              :now-active="nowActive"
              @nowactive="setNowActive"
            /><cdn-feature
              title="超大文件下载"
              :active="1"
              :now-active="nowActive"
              @nowactive="setNowActive"
            />
            <cdn-feature
              title="音视频点播"
              :active="2"
              :now-active="nowActive"
              @nowactive="setNowActive"
            />
            <cdn-feature
              title="动态加速"
              :active="3"
              :now-active="nowActive"
              @nowactive="setNowActive"
            />
            <cdn-feature
              title="全球加速"
              :active="4"
              :now-active="nowActive"
              @nowactive="setNowActive"
            />
          </div>
          <div class="cdn-info">
            <div class="image-wrap">
              <div
                class="image"
                v-show="nowActive === 0"
                id="feature1-image"
              ></div>
              <div
                class="image"
                v-show="nowActive === 1"
                id="feature2-image"
              ></div>
              <div
                class="image"
                v-show="nowActive === 2"
                id="feature3-image"
              ></div>
              <div
                class="image"
                v-show="nowActive === 3"
                id="feature4-image"
              ></div>
              <div
                class="image"
                v-show="nowActive === 4"
                id="feature5-image"
              ></div>
            </div>
            <div class="text" v-show="nowActive === 0">
              <div class="text-title">场景描述</div>
              <div class="text-content">
                网页静态资源优化加速分发，例如：HTML、CSS、JS、IMG、短视频等。
              </div>
              <div class="text-title">业务价值</div>
              <div class="text-dot-content">
                <div class="dot" />
                <div>
                  全站 HTTPS 保证网站访问安全；TCP
                  压缩优化使网页大图、样式等完成秒级加载，缩短网页响应时间，提高用户体验。
                </div>
              </div>
              <div class="text-dot-content">
                <div class="dot" />
                <div>
                  配合国信云-图片处理服务一起使用，还可以针对图片进行缩略、打水印、转格式等数据处理服务。
                </div>
              </div>
            </div>
            <div class="text" v-show="nowActive === 1">
              <div class="text-title">场景描述</div>
              <div class="text-content">
                大文件下载优化加速分发，例如：APK、MP3、EXE、ZIP 等。
              </div>
              <div class="text-title">业务价值</div>
              <div class="text-dot-content">
                <div class="dot" />
                <div>
                  国信 CDN
                  在全球数千个节点，让用户在下载过程中获得更快的下载速度。
                </div>
              </div>
              <div class="text-dot-content">
                <div class="dot" />
                <div>并利用分段缓存技术提高了大文件下载传输的稳定性。</div>
              </div>
              <div class="text-dot-content">
                <div class="dot" />
                <div>
                  配合国信云-存储服务一起使用，可有效降低回源和存储成本。
                </div>
              </div>
            </div>
            <div class="text" v-show="nowActive === 2">
              <div class="text-title">场景描述</div>
              <div class="text-content">
                音视频点播优化加速服务，例如：MP4、FLV、RMVB、WMV、HLS 等。
              </div>
              <div class="text-title">业务价值</div>
              <div class="text-dot-content">
                <div class="dot" />
                <div>
                  国信 CDN 利用 BGP
                  网络中间源技术降低回源带宽压力节省了用户回源成本。
                </div>
              </div>
              <div class="text-dot-content">
                <div class="dot" />
                <div>分段预取技术使用户在浏览音视频时更加的流畅。</div>
              </div>
              <div class="text-dot-content">
                <div class="dot" />
                <div>高级防盗链技术有效防止用户文件被盗用。</div>
              </div>
              <div class="text-dot-content">
                <div class="dot" />
                <div>
                  配合国信云-数据处理服务，还可以进行音视频转码、转格式、压缩等数据处理服务。
                </div>
              </div>
            </div>
            <div class="text" v-show="nowActive === 3">
              <div class="text-title">场景描述</div>
              <div class="text-content">
                动态加速针对动态资源进行加速分发，在电商、社交、社区论坛、金融支付和游戏娱乐等方面可以显著提升用户的访问性能。
              </div>
              <div class="text-title">业务价值</div>
              <div class="text-dot-content">
                <div class="dot" />
                <div>
                  借助特有的动态路由探测技术和私有传输协议优化，可以保证客户源站的内容通过安全稳定的最优链路分发到用户端。
                </div>
              </div>
            </div>
            <div class="text" v-show="nowActive === 4">
              <div class="text-title">场景描述</div>
              <div class="text-content">全球加速，源站在国内的场景。</div>
              <div class="text-title">业务价值</div>
              <div class="text-dot-content">
                <div class="dot" />
                <div>跨国回源链路质量。</div>
              </div>
              <div class="text-dot-content">
                <div class="dot" />
                <div>海外低命中资源访问时，降低响应时间，提升下载速度。</div>
              </div>
            </div>
          </div>
        </div>
      </sub-content>
      <sub-content title="核心功能">
        <div class="core-list">
          <cdn-core
            image="core1"
            title="优化成本和效率"
            content="内容就近缓存，减少回源，150Tbps+弹性带宽应对业务的波动。"
          />
          <cdn-core
            image="core2"
            title="无盲区覆盖"
            content="精选主流厂商高质量节点，全面覆盖各地区、各运营商的网络，实现全地域无盲区的覆盖"
          />
          <cdn-core
            image="core3"
            title="加速云端服务"
            content="服务间深度联动，云资源就近分发，提升终端访问性能。"
          />
          <cdn-core
            image="core4"
            title="降低回源成本"
            content="自建中间源，消除跨运营商回源慢甚至不可达等问题，保护源站不受边缘节点请求波动影响，节省回源成本"
          />
          <cdn-core
            image="core5"
            title="灵活分时计费"
            content="日间、闲时、忙时可分开计费，根据使用场景精细化运营，为用户最大化降低成本费用"
          />
          <cdn-core
            image="core6"
            title="私有协议优化"
            content="使用私有飞安达传输协议作为 CDN 内部数据交换的基础，确保客户资源在 CDN 内部安全、快速的传输到各个节点，实现客户内容高效、可靠的进行分发"
          />
        </div>
      </sub-content>
    </div>
    <contact-us></contact-us>
    <bottom-info></bottom-info>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import Banner from "../components/Banner.vue";
import SubContent from "../components/SubContent.vue";
import CdnFeature from "./components/CDNFeature.vue";
import ContactUs from "../components/ContactUs.vue";
import BottomInfo from "../components/BottomInfo.vue";
import CdnAdvantage from "./components/CDNAdvantage.vue";
import CdnCore from "./components/CDNCore.vue";
export default {
  name: "cdn",
  components: {
    TopBar,
    Banner,
    SubContent,
    CdnFeature,
    ContactUs,
    BottomInfo,
    CdnAdvantage,
    CdnCore,
  },
  data() {
    return {
      nowActive: 0,
    };
  },
  methods: {
    setNowActive(val) {
      this.nowActive = val;
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.cdn {
  .sub-content-list {
    .cdn-advantage-list {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 78px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
        margin-top: -45px;
      }
    }
    .cdn-feature-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 87px;
      .cdn-feature-list {
        display: flex;
        width: 850px;
        justify-content: space-between;
      }
      .cdn-info {
        width: 1158px;
        border-top: 1px solid #dcdcdc;
        display: flex;
        padding: 20px;
        align-items: center;
        .image-wrap {
          width: 734px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 40px 24px;
          .image {
            width: 671px;
          }
          #feature1-image {
            @include bg-image("../../assets/products/CDN/feature1");
            height: 378px;
          }
          #feature2-image {
            @include bg-image("../../assets/products/CDN/feature2");
            height: 378px;
          }
          #feature3-image {
            @include bg-image("../../assets/products/CDN/feature3");
            height: 378px;
          }
          #feature4-image {
            @include bg-image("../../assets/products/CDN/feature4");
            height: 378px;
          }
          #feature5-image {
            @include bg-image("../../assets/products/CDN/feature5");
            height: 420px;
          }
        }
        .text {
          line-height: 22.4px;
          width: 440px;
          margin-left: 40px;
          font-weight: 400;
          color: #8796aa;
          font-size: 14px;
          background-color: white;
          align-self: stretch;
          padding: 34px 28px;
          .text-title {
            font-size: 18px;
            font-weight: 400;
            color: #15224a;
            line-height: 30px;
            margin-bottom: 18px;
          }
          .text-content {
            font-size: 14px;
            font-weight: 400;
            color: #5e7290;
            line-height: 22px;
            margin-bottom: 26px;
          }
          .text-dot-content {
            display: flex;
            margin-bottom: 19px;
            div {
              font-size: 14px;
              font-weight: 400;
              color: #5e7290;
              line-height: 22px;
            }
            .dot {
              flex-shrink: 0;
              width: 6px;
              height: 6px;
              background: #145cf6;
              border-radius: 50%;
              margin-right: 12px;
              margin-top: 8px;
            }
          }
        }
      }
    }
    .core-list {
      width: 1220px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 130px;
    }
  }
}
</style>