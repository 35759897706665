<template>
  <div class="network-feature">
    <div class="image" :id="image"></div>
    <div class="text">
      <div class="title">{{ title }}</div>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "network-feature",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.network-feature {
  background-color: #f8faff;
  @media screen and (max-width: 812px) {
    width: calc(50% - 6px);
    margin-bottom: 12px;
  }
  .image {
    width: 280px;
    height: 186px;
    @media screen and (max-width: 812px) {
      width: 100%;
      height: 0;
      padding-bottom: 66.7%;
    }
  }
  #expensive {
    @include bg-image("../../../assets/products/Network/expensive");
  }
  #experience {
    @include bg-image("../../../assets/products/Network/experience");
  }
  #unsafe {
    @include bg-image("../../../assets/products/Network/unsafe");
  }
  #devops {
    @include bg-image("../../../assets/products/Network/devops");
  }
  .text {
    height: 180px;
    padding-left: 34px;
    @media screen and (max-width: 812px) {
      height: 110px;
      padding-left: 9.1%;
    }
    .title {
      margin-top: 27px;
      color: #15224a;
      font-size: 24px;
      @media screen and (max-width: 812px) {
        margin-top: 16px;
        font-size: 14px;
      }
    }
    .content {
      margin-top: 21px;
      line-height: 24px;
      color: #5e7290;
      font-size: 14px;
      font-weight: 400;
      @media screen and (max-width: 812px) {
        margin-top: 10px;
        line-height: 20px;
        width: 130%;
        font-size: 9px;
        transform: scale(0.75);
        transform-origin: 0 0;
      }
    }
  }
}
</style>