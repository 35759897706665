<template>
  <div class="network">
    <top-bar active="products"></top-bar>
    <banner
      image="network"
      title="SD-WAN"
      content="依托全球的IDC与骨干POP资源，建立SDWAN的多冗余控制台，提供可靠、稳定、安全的SDWAN组网方式，满足企业全球快速部署的需求"
    ></banner>
    <div class="sub-content-list">
      <sub-content title="传统网络组网面临问题">
        <div class="network-feature-list">
          <network-feature image="expensive" title="高成本">
            • 传统专线费用贵<br />
            • 网络运维成本高<br />
            • 设备扩展费用高<br />
            • 紧耦合，扩展性差
          </network-feature>
          <network-feature image="experience" title="体验差">
            • Internet服务质量无保障<br />
            • 对业务不感知，无法获知应用状态<br />
            • 应用数量暴增，关键业务难保护<br />
            • 云资源访问慢，远程协同性差
          </network-feature>
          <network-feature image="unsafe" title="不安全">
            • 核心业务安全<br />
            • 设备/人/物安全<br />
            • 基础设施和网络安全<br />
            • 缺乏端到端内安全防护
          </network-feature>
          <network-feature image="devops" title="运维难">
            • 故障数天处理，被动响应效率低<br />
            • CU管理低效，无法掌握网络全局<br />
            • VPN设备运维复杂，上线周期长<br />
            • 业务流量不可视，故障定位难
          </network-feature>
        </div>
      </sub-content>
      <sub-content
        title="SD-WAN架构"
        subtitle="SD-WAN云互联架构，结合了SSL-VPN和MPLS-VPN技术，为企业用户快速搭建企业专线通道，以实现统一管理和轻松运维。企业用户基于本地Internet接上云路由，即可就近接入POP节点进行虚拟隧道的对接，体验高速、稳定、安全、可靠的SD-WAN专线服务。"
      >
        <div id="sd-wan-image"></div>
      </sub-content>
      <sub-content title="产品优势" bgcolor="#F8FAFF">
        <div class="network-advantage-list">
          <network-advantage
            image="solution"
            title="弹性方案"
            content="自主研发的系统，支持企业最优方案的个性化定制"
          ></network-advantage>
          <network-advantage
            image="quick"
            title="快速部署"
            content="工单快速响应，秒级部署上线，支持先试运行"
          ></network-advantage>
          <network-advantage
            image="cheap"
            title="节省成本"
            content="与传统的MPLS和WAN对比，成本可节省高达70%"
          ></network-advantage>
          <network-advantage
            image="performance"
            title="优化性能"
            content="提高企业访问关键业务的应用程序性能"
          ></network-advantage>
          <network-advantage
            image="visibility"
            title="可视配置"
            content="可视化的网络配置，让网络不再是过去的黑盒子"
          ></network-advantage>
          <network-advantage
            image="mamagement"
            title="专业管理"
            content="7*24小时的技术支撑，SLA服务范围内全程护航"
          ></network-advantage>
        </div>
      </sub-content>
      <sub-content title="节点资源">
        <world-map></world-map>
      </sub-content>
      <sub-content
        title="应用场景"
        subtitle="SD-WAN，是具备高弹性与整合性的多元化解决方案，可针对客户不同使用场景的网络情况，定制真正满足企业业务需求的升级式网络解决方案"
      >
        <div class="scene-list">
          <scene image="store" title="连锁零售门店"></scene>
          <scene image="remote" title="企业远程办公"></scene>
          <scene image="connection" title="分支机构互联"></scene>
          <scene image="monitor" title="视频监控回传"></scene>
          <scene image="meeting" title="跨国视频会议"></scene>
        </div>
      </sub-content>
    </div>
    <contact-us></contact-us>
    <bottom-info></bottom-info>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import Banner from "../components/Banner.vue";
import SubContent from "../components/SubContent.vue";
import NetworkFeature from "./components/NetworkFeature.vue";
import NetworkAdvantage from "./components/NetworkAdvantage.vue";
import WorldMap from "./components/WorldMap.vue";
import Scene from "./components/Scene.vue";
import ContactUs from "../components/ContactUs.vue";
import BottomInfo from "../components/BottomInfo.vue";
export default {
  name: "network",
  components: {
    TopBar,
    Banner,
    SubContent,
    NetworkFeature,
    NetworkAdvantage,
    WorldMap,
    Scene,
    ContactUs,
    BottomInfo,
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.network {
  .sub-content-list {
    .network-feature-list {
      display: flex;
      width: 1200px;
      justify-content: space-between;
      margin-bottom: 6px;
      @media screen and (max-width: 1200px) {
        margin-top: -42px;
        max-width: calc(100% - 35px);
        flex-wrap: wrap;
        padding: 0 17.5px;
      }
    }
    #sd-wan-image {
      width: 993px;
      height: 497px;
      @include bg-image("../../assets/products/Network/sd-wan");
      margin-bottom: 82px;
      @media screen and (max-width: 993px) {
        margin: 15px auto 58px;
        width: 91.4%;
        height: 0;
        padding-bottom: 45.7%;
      }
    }
    .network-advantage-list {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 73px;
      @media screen and (max-width: 1200px) {
        max-width: calc(100% - 24px);
        margin: -41.5px auto 45.5px;
      }
    }
    .scene-list {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 88px;
      @media screen and (max-width: 1200px) {
        margin: 0 auto 55.5px;
        justify-content: space-evenly;
        max-width: calc(100% - 14px);
      }
    }
    .scene-list:after {
      content: "";
      width: 388px;
    }
  }
}
</style>