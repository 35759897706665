<template>
  <div class="world-map">
    <div class="position-wrap" id="india">
      <div class="position"></div>
      <div class="title">印度</div>
    </div>
    <div class="position-wrap" id="japan">
      <div class="position"></div>
      <div class="title">日本</div>
    </div>
    <div class="position-wrap" id="korea">
      <div class="position"></div>
      <div class="title">韩国</div>
    </div>
    <div class="position-wrap" id="singapore">
      <div class="position"></div>
      <div class="title">新加坡</div>
    </div>
    <div class="position-wrap" id="thailand">
      <div class="position"></div>
      <div class="title">泰国</div>
    </div>
    <div class="position-wrap" id="turkey">
      <div class="position"></div>
      <div class="title">土耳其</div>
    </div>
    <div class="position-wrap" id="uae">
      <div class="position"></div>
      <div class="title">阿联酋</div>
    </div>
    <div class="position-wrap" id="australia">
      <div class="position"></div>
      <div class="title">澳大利亚</div>
    </div>
    <div class="position-wrap" id="usa">
      <div class="position"></div>
      <div class="title">美国</div>
    </div>
    <div class="position-wrap" id="brazil">
      <div class="position"></div>
      <div class="title">巴西</div>
    </div>
    <div class="position-wrap" id="france">
      <div class="position"></div>
      <div class="title">法国</div>
    </div>
    <div class="position-wrap" id="uk">
      <div class="position"></div>
      <div class="title">英国</div>
    </div>
    <div class="position-wrap" id="russia">
      <div class="position"></div>
      <div class="title">俄罗斯</div>
    </div>
    <div class="position-wrap" id="sweden">
      <div class="position"></div>
      <div class="title">瑞典</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "world-map",
  mounted() {
    this.randomTwinkle();
    setInterval(this.randomTwinkle, 5000);
  },
  methods: {
    randomTwinkle() {
      let arr = Array.from(document.getElementsByClassName("position-wrap"));
      let ramdomArr = this.makeRandomArr(arr, 6);
      arr.forEach((item) => {
        item.childNodes[0].style.animationPlayState = "paused";
      });
      ramdomArr.forEach((item) => {
        item.childNodes[0].style.animationPlayState = "running";
      });
    },
    makeRandomArr(arrList, num) {
      if (num > arrList.length) {
        return;
      }
      let tempArr = arrList.slice(0);
      let newArrList = [];
      for (let i = 0; i < num; i++) {
        let random = Math.floor(Math.random() * (tempArr.length - 1));
        let arr = tempArr[random];
        tempArr.splice(random, 1);
        newArrList.push(arr);
      }
      return newArrList;
    },
  },
  beforeDestroy() {
    clearInterval(this.randomTwinkle);
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
@keyframes twinkle-node {
  from {
    box-shadow: 0 0 0 0 #ffffff;
  }
  50% {
    box-shadow: 0 0 0 3px #85aaf7;
  }
  to {
    box-shadow: 0 0 0 0 #c9e0f4;
  }
}
.world-map {
  margin-top: 50px;
  position: relative;
  width: 1101px;
  height: 532px;
  @include bg-image("../../../assets/Index/map");
  margin-bottom: 100px;
  @media screen and (max-width: 812px) {
    margin: -34px auto 43.5px;
    width: 92.1%;
    height: 0;
    padding-bottom: 44.5%;
    margin-bottom: 0;
  }
  .position-wrap {
    position: absolute;
    display: flex;
    align-items: center;
    .position {
      animation-play-state: paused;
      animation-fill-mode: backwards;
      animation-name: twinkle-node;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      height: 9px;
      width: 9px;
      border-radius: 50%;
      background-color: #145cf6;
      box-shadow: 0 0 0 2.5px #85aaf7;
      flex-shrink: 0;
    }
    .title {
      margin-left: 6px;
      font-size: 12px;
      font-weight: 400;
      color: #182940;
      white-space: nowrap;
    }
  }
  #cambodia {
    top: 280px;
    left: 861px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #hong-kong {
    top: 237px;
    left: 895px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #india {
    top: 255px;
    left: 775px;
    @media screen and (max-width: 812px) {
      top: 50%;
      left: 70%;
    }
  }
  #japan {
    top: 186px;
    left: 971px;
    @media screen and (max-width: 812px) {
      top: 34%;
      left: 87%;
    }
  }
  #korea {
    top: 190px;
    left: 930px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #malaysia {
    top: 303px;
    left: 853px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #taiwan {
    top: 229px;
    left: 915px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #singapore {
    top: 313px;
    left: 858px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #thailand {
    top: 259px;
    left: 848px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #vietnam {
    top: 269px;
    left: 874px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #jakarta {
    top: 345px;
    left: 864px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #turkey {
    top: 180px;
    left: 638px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #uae {
    top: 232px;
    left: 705px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #australia {
    top: 445px;
    left: 990px;
    @media screen and (max-width: 812px) {
      top: 75%;
      left: 85%;
    }
  }
  #newzealand {
    top: 480px;
    left: 1070px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #usa {
    top: 195px;
    left: 270px;
    @media screen and (max-width: 812px) {
      top: 35%;
      left: 25%;
    }
  }
  #canada {
    top: 130px;
    left: 225px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #brazil {
    top: 390px;
    left: 380px;
    @media screen and (max-width: 812px) {
      top: 68%;
      left: 35%;
    }
  }
  #argentina {
    top: 460px;
    left: 345px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #france {
    top: 152px;
    left: 537px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #german {
    top: 132px;
    left: 565px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #uk {
    top: 127px;
    left: 530px;
    @media screen and (max-width: 812px) {
      top: 22%;
      left: 48%;
    }
  }
  #italy {
    top: 162px;
    left: 575px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #russia {
    top: 112px;
    left: 700px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #spain {
    top: 173px;
    left: 528px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #sweden {
    top: 108px;
    left: 580px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
  #ukraine {
    top: 140px;
    left: 638px;
    @media screen and (max-width: 812px) {
      display: none;
    }
  }
}
</style>