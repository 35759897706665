<template>
  <div class="news-detail">
    <div class="breadcrumb">
      <span style="cursor: pointer" @click="handleToNewsList">新闻中心</span>
      <span>&nbsp;>&nbsp;</span>
      <span>热点</span>
    </div>
    <div class="title">{{ title }}</div>
    <div class="time">
      <Clock />
      <span>{{ publishedAt }}</span>
    </div>
    <div class="content">
      <div v-html="markdownToHtml"></div>
    </div>
    <div class="pagination">
      <div @click="handleToDetail(previousId)">上一篇：{{ previousTitle }}</div>
      <div @click="handleToDetail(nextId)">下一篇：{{ nextTitle }}</div>
    </div>
  </div>
</template>
  
<script>
import marked from "marked";
import { getNewsDetail } from "../../../services/api";
import { getPrevAndNextNewsTitle } from "../../../services/graph-ql";
import ContentService from "../../../services/content-service";
import Clock from "../../../assets/News/time.svg";

const noTitle = "没有了";

export default {
  name: "news-detail",
  components: {
    Clock,
  },
  computed: {
    markdownToHtml() {
      return marked(ContentService.prependPrefixToImgSrc(this.content));
    },
  },
  props: {},
  data() {
    return {
      title: "",
      publishedAt: "",
      content: "",
      previousTitle: noTitle,
      previousId: "",
      nextTitle: noTitle,
      nextId: "",
    };
  },
  mounted() {
    this.getData();
    this.getTitle();
  },
  watch: {
    content: function () {
      this.$nextTick(() => {
        // 在下一个 DOM 更新周期中应用样式
        this.applyStyles();
      });
    },
    $route: function () {
      this.getData();
      this.getTitle();
    },
  },
  methods: {
    handleToNewsList() {
      this.$router.push("/news/list");
    },
    getData() {
      getNewsDetail({
        id: this.$route.params.id,
      }).then((res) => {
        const { data } = res;
        const { attributes } = data;
        const { title, publishedAt, content } = attributes;
        this.title = title;
        this.publishedAt = new Date(publishedAt)
          .toLocaleDateString()
          .split("/")
          .join("-");
        this.content = content;
      });
    },
    applyStyles() {
      const images = this.$el.querySelectorAll("img");
      images.forEach((img) => {
        img.style.maxWidth = "780px";
        img.style.display = "block";
        img.style.margin = "0 auto";
      });
    },
    getTitle() {
      getPrevAndNextNewsTitle({
        currentNewsId: this.$route.params.id,
      }).then((res) => {
        const { previousNews, nextNews } = res;
        if (previousNews.data.length) {
          this.previousId = previousNews.data[0].id;
          this.previousTitle = previousNews.data[0].attributes.title;
        } else {
          this.previousId = "";
          this.previousTitle = noTitle;
        }
        if (nextNews.data.length) {
          this.nextId = nextNews.data[0].id;
          this.nextTitle = nextNews.data[0].attributes.title;
        } else {
          this.nextId = "";
          this.nextTitle = noTitle;
        }
      });
    },
    handleToDetail(id) {
      id && this.$router.push(`/news/detail/${id}`);
    },
  },
};
</script>
  
  <style scoped lang="scss">
.news-detail {
  width: 780px;
  .breadcrumb {
    font-size: 14px;
    font-weight: 400;
    color: #15224a;
  }
  .title {
    margin-top: 36px;
    font-size: 24px;
    font-weight: 400;
    color: #15224a;
  }
  .time {
    display: flex;
    align-items: center;
    svg {
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
    margin-top: 14px;
    font-size: 12px;
    font-weight: 400;
    color: #5e7290;
  }
  .content {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #5e7290;
    line-height: 28px;
  }
  .pagination {
    margin-top: 34px;
    font-size: 14px;
    font-weight: 400;
    color: #15224a;
    line-height: 28px;
    div {
      cursor: pointer;
    }
  }
}
</style>
  