<template>
  <div class="pagination">
    <template v-if="total > size">
      <div
        :class="'wrapper' + (current === 1 ? ' prevent' : '')"
        @click="handleCurrent(current - 1)"
      >
        <prev />
      </div>
      <div
        :class="'wrapper' + (current === 1 ? ' current' : '')"
        @click="handleCurrent(1)"
      >
        1
      </div>
      <div class="wrapper prevent" v-if="current > 4">...</div>
      <template v-if="leftRangePages > 0">
        <div
          class="wrapper"
          v-for="(item, index) in leftRangePages"
          :key="'left' + index"
          @click="handleCurrent(current - leftRangePages + index)"
        >
          {{ current - leftRangePages + index }}
        </div>
      </template>
      <div class="wrapper current" v-if="current > 1 && current < pages">
        {{ current }}
      </div>
      <template v-if="rightRangePages > 0"
        ><div
          class="wrapper"
          v-for="(item, index) in rightRangePages"
          :key="'right' + index"
          @click="handleCurrent(current + index + 1)"
        >
          {{ current + index + 1 }}
        </div>
      </template>
      <div class="wrapper prevent" v-if="current < pages - 3">...</div>
      <div
        :class="'wrapper' + (current === pages ? ' current' : '')"
        @click="handleCurrent(pages)"
      >
        {{ pages }}
      </div>
      <div
        :class="'wrapper' + (current === pages ? ' prevent' : '')"
        @click="handleCurrent(current + 1)"
      >
        <next />
      </div>
    </template>
  </div>
</template>

<script>
import Prev from "../../common/image/pagination-prev.svg";
import Next from "../../common/image/pagination-next.svg";
export default {
  name: "pagination",
  components: {
    Prev,
    Next,
  },
  computed: {
    pages() {
      return Math.ceil(this.total / this.size);
    },
    leftRangePages() {
      return this.current > 3 ? 2 : this.current - 2 ?? 0;
    },
    rightRangePages() {
      return this.current < this.pages - 2
        ? 2
        : this.pages - this.current - 1 ?? 0;
    },
  },
  props: {
    current: {
      type: Number,
    },
    total: {
      type: Number,
    },
    size: {
      type: Number,
    },
  },
  methods: {
    handleCurrent(c) {
      this.$emit("current", c < 1 ? 1 : c > this.pages ? this.pages : c);
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";

.pagination {
  display: flex;
  margin-left: 16px;
  margin-top: 16px;
  .wrapper {
    margin-right: 8px;
    width: 30px;
    height: 30px;
    border: 1px solid #a6abb3;
    border-radius: 4px;
    color: #5e7290;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    > svg {
      width: 32px;
      height: 32px;
    }
  }
  .prevent {
    cursor: not-allowed;
  }
  .current {
    background: #145cf6;
    border: 1px solid #145cf6;
    color: white;
  }
}
</style>