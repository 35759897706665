<template>
  <div class="cdn-core">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
    <div class="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "cdn-core",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.cdn-core {
  width: 384px;
  height: 260px;
  background: white;
  box-shadow: 0 0 30px 0 #eef3ff;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .image {
    margin-top: 35px;
    width: 66px;
    height: 66px;
  }
  #core1 {
    @include bg-image("../../../assets/products/CDN/core1");
  }
  #core2 {
    @include bg-image("../../../assets/products/CDN/core2");
  }
  #core3 {
    @include bg-image("../../../assets/products/CDN/core3");
  }
  #core4 {
    @include bg-image("../../../assets/products/CDN/core4");
  }
  #core5 {
    @include bg-image("../../../assets/products/CDN/core5");
  }
  #core6 {
    @include bg-image("../../../assets/products/CDN/core6");
  }
  .title {
    color: #15224a;
    font-weight: 400;
    font-size: 24px;
    margin-top: 21px;
    margin-bottom: 23px;
  }
  .content {
    color: #5e7290;
    font-weight: 400;
    font-size: 14px;
    width: 322px;
    line-height: 24px;
    text-align: center;
  }
}
.cdn-core:hover {
  box-shadow: 0 0 30px 0 #e1e6f1;
}
</style>