<template>
  <div class="requirement">
    <div class="number">{{ number }}</div>
    <div class="content" v-if="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "requirement",
  props: {
    number: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.requirement {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  .number {
    text-align: center;
    line-height: 50px;
    width: 50px;
    height: 50px;
    background: #145cf6;
    border-radius: 50%;
    font-weight: 400;
    font-size: 36px;
    color: white;
  }
  .content {
    margin-top: 22px;
    text-align: center;
    color: #182940;
    font-weight: 400;
    font-size: 16x;
    width: 245px;
    line-height: 24px;
  }
}
</style>