<template>
  <div class="cts-gms-nts">
    <top-bar active="solutions" theme="white"></top-bar>
    <solutions-banner image="cts-gms-nts">
      CTS/GMS/NTS等认证<br />
      网络优化解决方案
    </solutions-banner>
    <div class="sub-content-list">
      <sub-content title="CTS/GMS/NTS 认证" bgcolor="#F8FAFF">
        <div class="certification-list">
          <certification
            image="cts"
            title="CTS认证"
            content="Compatibility Test Suite，即谷歌兼容性认证。经过认证的设备才能正常运行所有基于Android开发的应用，才允许使用 Google Play 应用商店，不会因为系统兼容性导致崩溃等异常问题。"
          >
          </certification>
          <certification
            image="gms"
            title="GMS认证"
            content="Google移动服务，指一系列精选的Google专有应用程序和包含Google本质的服务。Chrome，Gmail，Youtube，PlayStore 等应用程序是GMS应用程序的一部分。要使用所有Google专有应用程序运行Android设备，必须获得GMS认证，否则无法运行。"
          >
          </certification>
          <certification
            image="nts"
            title="NTS认证"
            content="Netflix Test Studio，是 Netflix为内部和外部开发人员创建一套标准的工具，用于应用的部署和执行测试，用于评估整体体验的响应度和质量，且能远程控制Netflix Ready 设备。"
          >
          </certification>
        </div>
      </sub-content>
      <sub-content title="CTS/GMS/NTS等认证优化需求">
        <div class="requirement-list">
          <requirement
            number="1"
            content="可提供多个国家（地区）当地网络IP地址，自由切换"
          ></requirement>
          <requirement
            number="2"
            content="访问海外YouTube、Netflix、Prime Video等多媒体视频网站"
          ></requirement>
          <requirement
            number="3"
            content="多路CTS、GMS、NTS等稳定测试，保证并发数支持"
          ></requirement>
          <requirement
            number="4"
            content="同时Netflix、Prime Video等多路4K UHD 60fps分辨率视频稳定播放"
          ></requirement>
          <requirement
            number="5"
            content="具备 IPv4/IPv6双协议栈，支持IPV4/IPV6 网络须支持同时输出"
          ></requirement>
          <requirement
            number="6"
            content="通过Netflix/Youtube/Amazon相关平台测试认证"
          ></requirement>
        </div>
      </sub-content>
      <sub-content title="解决方案价值" bgcolor="#F8FAFF">
        <div class="price-list">
          <price
            image="cts-gms-acceleration"
            title="CTS/GMS认证加速"
            content="针对CTS/GMS测试要求，我们提供高速稳定、安全可靠的IPv6网络接入服务。定制开发一站式的CTS测试环境整体方案，解决测试CTS认证关于“IPV6-Only测试Fail” 难题，直到您得到一个干净的报告。"
          ></price>
          <price
            image="nts-acceleration"
            title="NTS认证加速"
            content="针对Netflix官方认证要求，通过BGP优化路由选路，结合Fast.com测试标准进行线路优化，有效提供CTS测试报告的有效通过性和测试效率提高。该优化方案已在不同的国际线路中通过NTS压力测试。"
          ></price>
          <price
            image="youtube"
            title="YouTube认证加速"
            content="针对YouTube的传输协议进行针对性匹配，保证4K UHD 60fps分辨率视频稳定播放，有效提高多路高分辨率的测试效率。"
          ></price>
          <price
            image="prime-video"
            title="Prime Video认证加速"
            content="针对Amazon的海外站点实现最优路径选路，保证了Prime Video测试认证可以最优路径播放，实现4K UHD 60fps分辨率的播放的稳定播放，有效保证测试报告的测试通过完整性。"
          ></price>
        </div>
      </sub-content>
      <sub-content title="认证支持列表" bgcolor="#145CF6" color="white">
        <div class="surport-list">
          <div class="surport-image" id="contv-image"></div>
          <div class="surport-image" id="netflix-image"></div>
          <div class="surport-image" id="hulu-image"></div>
          <div class="surport-image" id="crackle-image"></div>
          <div class="surport-image" id="iheartradio-image"></div>
          <div class="surport-image" id="fandangonow-image"></div>
          <div class="surport-image" id="primevideo-image"></div>
          <div class="surport-image" id="vudu-image"></div>
          <div class="surport-image" id="youtube-image"></div>
          <div class="surport-image" id="xumo-image"></div>
        </div>
      </sub-content>
      <sub-content title="CTS/GMS/NTS认证优化方案">
        <div class="optimization-list">
          <optimization
            image="local"
            title="接入本地端"
            content="本地端采用专线（裸纤）进行接入，打通客户办公楼机房和国信网服网络机房两端，保证本地端到端的网络可靠性。"
          ></optimization>
          <optimization
            image="backbone"
            title="骨干互联"
            content="本地端接入国信网服POP骨干打通后，客户端到香港端实现了专线互联。通过国信网服低延迟的骨干网和优化协议直接访问YouTube和Netflix等骨干节点，提高认证测试的效率。"
          ></optimization>
          <optimization
            image="oversea"
            title="海外出口加速"
            content="采用运营商专线（异地机房主备线路）打通深圳端IDC和香港端IDC，通过海外一级运营商BGP广播后进行国际网络访问。"
          ></optimization>
          <optimization
            image="multiple-area"
            title="多地区加速"
            content="基于国信网服现有的海外节点，结合自研网络优化核心技术（Fastsync Gateway）解决到部分海外高延迟站点导致的问题，保证研发人员模拟当地网络环境实现测试认证。"
          ></optimization>
        </div>
      </sub-content>
      <sub-content
        title="网络优化核心技术"
        minititle="（自研Fastsync Gateway网络加速）"
        subtitle="国信网服（Fastsync Gateway）凭借业内领先的数据传输核心技术—飞安达（Fastsync）传输引擎，完美解决客户海外站点延迟高，丢包严重等问题。最大限度提高带宽效能，从而保证在不同国家（地区）都能稳定4K UHD视频播放，进而提高工作效率，获得客户一致好评。"
        bgcolor="#F8FAFF"
      >
        <div id="core-image"></div>
      </sub-content>
      <sub-content
        title="丰富的海外节点"
        minititle="国信网服整合高品质的运营商网络资源，针对海外视频访问加速问题，结合自研Fastsync Gateway协议加速提供安全、快速、稳定的国际互联网接入服务，实现多媒体视频网站极速浏览、国际视频瞬间加载等。"
      >
        <world-map></world-map>
      </sub-content>
      <sub-content title="案例分享" bgcolor="#F8FAFF">
        <div class="share-wrap">
          <div class="share-text-wrap">
            <div class="share-text">
              <div class="share-title">原有网络</div>
              <div class="share-content">
                1.办公研发、测试团队无法在深圳、合肥、武汉进行模拟海外网络环境办公。<br />
                2.加载YouTube、Netflix、Amazon、BBC等多媒体视频网站卡顿，访问较为缓慢。<br />
                3.无法高速稳定压力测试4K分辨率多媒体视频。<br />
                4.无法同时多路4K片源压测
              </div>
            </div>
            <div class="share-text">
              <div class="share-title">客户需求</div>
              <div class="share-content">
                1.高速访问海外YouTube、Netflix、Amazon、BBC等多媒体视频网站。<br />
                2.稳定高速压力测试4K分辨率视频。<br />
                3.支持同时多路4K片源进行播放。<br />
                4.切换灵活，支持多国家同时在线，客户端自行切换。
              </div>
            </div>
            <div class="share-text">
              <div class="share-title">改善方案</div>
              <div class="share-content">
                1.本地最后一公里：<br />
                ·
                本地（包含深圳、合肥、武汉）采用专线（裸纤）进行接入，打通客户办公楼机房和机房两端，保证本地端的网络可靠性。<br />
                ·
                合肥、武汉本地采用电信运营商点对点专线，结合骨干网打通全球网络互连。<br /><br />
                2.海外出口访问：<br />
                ·
                采用运营商专线（异地机房主备线路）打通深圳端IDC和香港端IDC，通过海外一级运营商进行BGP广播后进行国际网络访问。<br />
                ·
                客户端放置SD-WAN盒子，对接客户机房核心交换机。通过不同的VLAN区分地区，IT同事针对不同的测试人员进行不同端口划入对应VLAN，保证全公司均存在可接入的可能，提高了网络的灵活性。
              </div>
            </div>
          </div>
          <div id="share-image"></div>
        </div>
      </sub-content>
      <sub-content title="成功案例">
        <div class="success-list">
          <success image="lg"></success>
          <success image="konka"></success>
          <success image="hisence"></success>
          <success image="skyworth"></success>
          <success image="mediatek"></success>
          <success image="tcl"></success>
          <success image="cvte"></success>
          <success image="boe"></success>
        </div>
      </sub-content>
    </div>
    <contact-us></contact-us>
    <bottom-info></bottom-info>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import SolutionsBanner from "../components/SolutionsBanner.vue";
import SubContent from "../components/SubContent.vue";
import Certification from "./components/Certification.vue";
import Requirement from "./components/Requirement.vue";
import Price from "./components/Price.vue";
import Optimization from "./components/Optimization.vue";
import WorldMap from "./components/WorldMap.vue";
import Success from "./components/Success.vue";
import ContactUs from "../components/ContactUs.vue";
import BottomInfo from "../components/BottomInfo.vue";
export default {
  name: "cts-gms-nts",
  components: {
    TopBar,
    SolutionsBanner,
    SubContent,
    Certification,
    Requirement,
    Price,
    Optimization,
    WorldMap,
    Success,
    ContactUs,
    BottomInfo,
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.cts-gms-nts {
  .sub-content-list {
    .certification-list {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin-bottom: 79px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
    .requirement-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 1200px;
      margin-bottom: 20px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
    .price-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 1200px;
      margin-bottom: 83px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
    .surport-list {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 17px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
      .surport-image {
        width: 200.1px;
        height: 40px;
        margin-bottom: 70px;
      }
      #contv-image {
        @include bg-image("../../assets/solutions/CtsGmsNts/contv-image");
      }
      #netflix-image {
        @include bg-image("../../assets/solutions/CtsGmsNts/netflix-image");
      }
      #hulu-image {
        @include bg-image("../../assets/solutions/CtsGmsNts/hulu-image");
      }
      #crackle-image {
        @include bg-image("../../assets/solutions/CtsGmsNts/crackle-image");
      }
      #iheartradio-image {
        @include bg-image("../../assets/solutions/CtsGmsNts/iheartradio-image");
      }
      #fandangonow-image {
        @include bg-image("../../assets/solutions/CtsGmsNts/fandangonow-image");
      }
      #primevideo-image {
        @include bg-image("../../assets/solutions/CtsGmsNts/primevideo-image");
      }
      #vudu-image {
        @include bg-image("../../assets/solutions/CtsGmsNts/vudu-image");
      }
      #youtube-image {
        @include bg-image("../../assets/solutions/CtsGmsNts/youtube-image");
      }
      #xumo-image {
        @include bg-image("../../assets/solutions/CtsGmsNts/xumo-image");
      }
    }
    .optimization-list {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 92px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
    #core-image {
      margin-top: 26px;
      width: 920px;
      height: 283px;
      background-image: url("../../assets/solutions/CtsGmsNts/core2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      /* @include bg-image("../../assets/solutions/CtsGmsNts/core"); */
      margin-bottom: 105px;
    }
    .share-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 102px;
      .share-text-wrap {
        margin-right: 53px;
        .share-text {
          margin-bottom: 39px;
          .share-title {
            color: #15224a;
            margin-bottom: 20px;
            font-weight: 400;
            font-size: 20px;
          }
          .share-content {
            width: 506px;
            font-weight: 400;
            color: #5e7290;
            line-height: 22px;
            font-size: 14px;
          }
        }
        .share-text:last-child {
          margin-bottom: 0;
        }
      }
      #share-image {
        width: 641px;
        height: 556px;
        @include bg-image("../../assets/solutions/CtsGmsNts/share");
      }
    }
    .success-list {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 95px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
  }
}
</style>