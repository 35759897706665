<template>
  <div class="index-feature-tag">
    <div class="image" :id="image"></div>
    <div class="text" data-aos="fade-up" data-aos-duration="500">
      <div class="title">{{ title }}</div>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index-feature-tag",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.index-feature-tag {
  border-radius: 20px;
  background-color: white;
  width: 380px;
  box-shadow: 0 0 30px 0 rgba(148, 151, 207, 0.2);
  height: 200px;
  display: flex;
  @media screen and (max-width: 1200px) {
    margin-bottom: 14px;
  }
  @media screen and (max-width: 812px) {
    border-radius: 10px;
    max-width: 80%;
    height: 150px;
    box-shadow: 0 0 15px 0 rgba(148, 151, 207, 0.2);
    width: 300px;
  }
  .image {
    margin: 43px 49px 0 44px;
  }
  #service {
    width: 46px;
    height: 62px;
    @include bg-image("../../assets/Index/service");
    @media screen and (max-width: 812px) {
      width: 9.6%;
      height: 0;
      padding-bottom: 12.8%;
      margin: 30px 14.8% 0 12.7%;
    }
  }
  #source {
    width: 62px;
    height: 60px;
    @include bg-image("../../assets/Index/source");
    @media screen and (max-width: 812px) {
      width: 12.8%;
      height: 0;
      padding-bottom: 12.5%;
      margin: 29px 11.3% 0 13.9%;
    }
  }
  #cooperation {
    width: 62px;
    height: 50px;
    @include bg-image("../../assets/Index/cooperation");
    @media screen and (max-width: 812px) {
      width: 12.8%;
      height: 0;
      padding-bottom: 10.4%;
      margin: 33px 11.5% 0 13.6%;
    }
  }
  .text {
    margin-top: 38px;
    @media screen and (max-width: 812px) {
      margin-top: 25px;
    }
    .title {
      color: #15224a;
      font-size: 30px;
      margin-bottom: 14px;
      @media screen and (max-width: 812px) {
        font-size: 24.5px;
      }
    }
    .content {
      line-height: 26px;
      color: #5e7290;
      font-size: 16px;
      font-weight: 400;
      @media screen and (max-width: 812px) {
        font-size: 13px;
        line-height: 21.125px;
      }
    }
  }
}
.index-feature-tag:hover {
  box-shadow: 0 0 30px 0 rgba(148, 151, 207, 0.4);
}
</style>