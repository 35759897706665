<template>
  <div class="streaming">
    <top-bar active="solutions" theme="white"></top-bar>
    <solutions-banner
      image="streaming"
      content="飞安发利用自研高速传输系统，实现带宽利用率最大化、数据高可靠、智能化数据同步、统一管理和监管。结合CDN技术骨干网实现节点之间的端到端方式共享、缓存数据，提供文件高速传输传输服务。采用了金融级传输加密算法，全程保证数据传输的安全性。通过严格限制端到端范围和客户端监控实现了网络可管理性和流量监管。"
    >
      流媒体高速分发解决方案
    </solutions-banner>
    <div class="sub-content-list">
      <sub-content title="行业现状" bgcolor="#F8FAFF">
        <div class="present-list">
          <present-situation
            image="harddisk"
            title="硬盘拷贝"
            content="硬盘拷贝是一种通过存储介质传输影片的方式。影院将影片拷贝到硬盘中，再通过数字放映机链接网络获取授权密钥进行放映。这种传输方式速度快，但也存在安全性和可靠性的问题。如果硬盘丢失或损坏，将会影响放映效率和文件安全。"
          />
          <present-situation
            image="satellite"
            title="卫星传输"
            content="卫星传输是一种高效的一对多传输方式，通过卫星接收设备从片源方接收影片，并能覆盖全国范围。该传输方式速度快，但需要专业设备。"
          />
          <present-situation
            image="fiber"
            title="光纤传输"
            content="光纤传输需要片源方和影院都有光纤接入，具有高速、稳定的特点。但对光纤带宽对覆盖存在问题，面对大面积用户时，铺设光纤对于一些较为偏远将是一个不现实方案。"
          />
        </div>
      </sub-content>
      <sub-content
        title="FCDN 影视大文件一站式高速分发解决方案"
        textAlign="left"
        subtitle="飞安达高速传输系统结合CDN技术构成一站式解决方案，覆盖了影视生命周期管理，提供文件传输加速服务。该方案适用于影视制作、后期制作、特效渲染、剪辑配音、影片传输、放映等全过程，为影院提供集中化运营管理和实时、定点、精准传输模式的技术支持。飞安达传输基于独立研发的高速传输协议FastSync，支持大文件高速分发，且全程采用金融级传输加密算法保证传输的高速、稳定、可靠。"
        subtitle2="该模式采用传统CDN技术将影视媒体内容从中心推送至网络边缘服务节点缓存，然后再使用飞安达传输技术进行边缘节点到用户之间的传输。如果本地节点没有用户需要的内容，节点会通过CDN网络向中心服务器索取，拿到本地之后再向本地用户点对点分发。这种模式充分利用了飞安达系统与CDN各自的特性，利用CDN服务高保障的特性推送至离用户最近的服务节点，又利用飞安达系统的可扩展性提升了节点的可服务能力。"
      >
        <div class="highspeed-wrap">
          <div id="highspeed-image"></div>
        </div>
      </sub-content>
      <sub-content title="方案优势" bgcolor="#F8FAFF">
        <div class="advantage-list">
          <streaming-advantage
            image="high-speed-transfer"
            title="高速传输"
            content="飞安达传输技术和CDN一站式解决方案可大大减轻中心数据源的压力，通过在CDN服务节点之间采用端到端方式进行数据共享和缓存，有效提升了文件传输效率，为影视后期制作和各地影院提供了高效、可靠的数字电影传输服务。这种技术支持端到端的自动传输，快速将内容传输到影院片库服务器并根据放映安排传输到各个影厅。"
          />
          <streaming-advantage
            image="data"
            title="数据高可靠"
            content="中心数据源进行多点备份，以提高系统的服务能力和可靠性。服务节点之间对中心数据进行多点备份，从而提高CDN系统的整体冗余和自我恢复能力，结合飞安达文件系统的断点续传，使得系统更加稳定可靠。"
          />
          <streaming-advantage
            image="ai"
            title="智能化数据同步"
            content="增加可提供服务的节点数量，提升服务灵活性。通过端到端方式互相共享、缓存数据，使得可以提供服务的节点数量大大增加，同时使得服务节点的服务更加灵活、智能。如流媒体VOD应用时，几个邻近节点可按照一定规则各自缓存不同的流媒体数据，如果A节点服务的用户请求观看B节点缓存的内容，可采取A节点与B节点通过端到端方式交换数据，由A节点为用户提供服务。"
          />
          <streaming-advantage
            image="safe"
            title="安全保障"
            content="FCDN方案采用金融级传输加密算法，全程保证数据传输的安全性。同时，该方案利用了CDN服务的高保障特性和飞安达系统的可扩展性，从而提高了数据的安全性和隐私性。通过将数据传输过程中的安全性和稳定性放在首位，为各类媒体内容的传输提供稳定、高效、安全的保障。"
          />
          <streaming-advantage
            image="unify"
            title="统一管理和监管"
            content="提高网络的可管理性，避免流量无序。系统将端到端的范围严格限制在某一边缘服务节点的服务区域内，避免了传统端到端技术造成的过多的跨地区、跨ISP造成骨干网拥塞、流量无序的问题。增强了网络的可管理性和服务高的可靠性。另外，通过客户端，可以实现对用户的监控，流量的监管。"
          />
        </div>
      </sub-content>
    </div>
    <contact-us></contact-us>
    <bottom-info></bottom-info>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import SolutionsBanner from "../components/SolutionsBanner.vue";
import SubContent from "../components/SubContent.vue";
import StreamingAdvantage from "./components/StreamingAdvantage.vue";
import ContactUs from "../components/ContactUs.vue";
import BottomInfo from "../components/BottomInfo.vue";
import PresentSituation from "./components/PresentSituation.vue";
export default {
  name: "streaming",
  components: {
    TopBar,
    SolutionsBanner,
    SubContent,
    StreamingAdvantage,
    ContactUs,
    BottomInfo,
    PresentSituation,
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.streaming {
  .sub-content-list {
    .present-list {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 100px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
    .highspeed-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 120px;
      #highspeed-image {
        width: 1200px;
        height: 460px;
        @include bg-image("../../assets/solutions/Streaming/highspeed");
      }
    }
    .advantage-list {
      display: flex;
      justify-content: center;
      margin-bottom: 97px;
      flex-wrap: wrap;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
  }
}
</style>