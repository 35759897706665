<template>
  <div class="profession">
    <div class="image" :id="image"></div>
    <div class="text">
      <div class="title">{{ title }}</div>
      <div class="content">{{ content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profession",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.profession {
  background-color: white;
  width: 540px;
  height: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 30px 0 #eef3ff;
  margin-bottom: 30px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 90px;
    box-shadow: 0 0 15px 0 #eef3ff;
    margin-bottom: 10px;
    justify-content: center;
  }
  .image {
    margin-left: 40px;
    @media screen and (max-width: 1200px) {
      margin-left: 0;
    }
  }
  #personal {
    width: 67px;
    height: 61px;
    @include bg-image("../../../assets/products/Server/personal");
    @media screen and (max-width: 1200px) {
      width: 33.5px;
      height: 30.5px;
    }
  }
  #allday {
    width: 50px;
    height: 60px;
    @include bg-image("../../../assets/products/Server/allday");
    @media screen and (max-width: 1200px) {
      width: 25px;
      height: 30px;
    }
  }
  #minutes {
    width: 59px;
    height: 60px;
    @include bg-image("../../../assets/products/Server/minutes");
    @media screen and (max-width: 1200px) {
      width: 29.5px;
      height: 30px;
    }
  }
  .text {
    margin-right: 45px;
    @media screen and (max-width: 1200px) {
      margin-right: 0;
      width: 72.4%;
      margin-left: 15px;
    }
    .title {
      color: #15224a;
      font-size: 30px;
      margin-bottom: 16px;
      @media screen and (max-width: 1200px) {
        font-size: 18px;
        margin-bottom: 9px;
      }
    }
    .content {
      line-height: 24px;
      width: 351px;
      color: #5e7290;
      font-size: 16px;
      font-weight: 400;
      @media screen and (max-width: 1200px) {
        width: 100%;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
.profession:hover {
  box-shadow: 0 0 30px 0 #e1e6f1;
}
</style>