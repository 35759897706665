<template>
  <div class="present-situation">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
    <div class="content" v-if="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "present-situation",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.present-situation {
  display: flex;
  flex-direction: column;
  align-items: center;
  .image {
    margin-bottom: 34px;
  }
  #harddisk {
    width: 100px;
    height: 100px;
    @include bg-image("../../../assets/solutions/Streaming/harddisk");
  }
  #satellite {
    width: 100px;
    height: 100px;
    @include bg-image("../../../assets/solutions/Streaming/satellite");
  }
  #fiber {
    width: 100px;
    height: 100px;
    @include bg-image("../../../assets/solutions/Streaming/fiber");
  }
  .title {
    color: #182940;
    font-weight: 400;
    font-size: 30px;
    margin-bottom: 26px;
  }
  .content {
    text-align: center;
    color: #5e7290;
    font-weight: 400;
    font-size: 14px;
    width: 351px;
    line-height: 24px;
  }
}
</style>