<template>
  <div class="server-advantage">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
    <div class="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "server-advantage",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.server-advantage {
  background-color: white;
  width: 278px;
  height: 278px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 40px 0 #eef3ff;
  @media screen and (max-width: 1200px) {
    width: 170px;
    height: 150px;
    box-shadow: 0 0 20px 0 #eef3ff;
    margin-bottom: 15px;
  }
  .image {
    margin-bottom: 37px;
    @media screen and (max-width: 1200px) {
      margin-bottom: 16px;
    }
  }
  #safety {
    width: 55px;
    height: 60px;
    @include bg-image("../../../assets/products/Server/safety");
    @media screen and (max-width: 1200px) {
      width: 27.5px;
      height: 30px;
    }
  }
  #immediately {
    width: 60px;
    height: 60px;
    @include bg-image("../../../assets/products/Server/immediately");
    @media screen and (max-width: 1200px) {
      width: 30px;
      height: 30px;
    }
  }
  #flexible {
    width: 59px;
    height: 60px;
    @include bg-image("../../../assets/products/Server/flexible");
    @media screen and (max-width: 1200px) {
      width: 29.5px;
      height: 30px;
    }
  }
  #performance {
    width: 60px;
    height: 60px;
    @include bg-image("../../../assets/products/Server/performance");
    @media screen and (max-width: 1200px) {
      width: 30px;
      height: 30px;
    }
  }
  .title {
    color: #15224a;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 24px;
    @media screen and (max-width: 1200px) {
      font-size: 15px;
      margin-bottom: 13px;
    }
  }
  .content {
    color: #5e7290;
    font-size: 16px;
    font-weight: 400;
    @media screen and (max-width: 1200px) {
      font-size: 12px;
      width: 100px;
      height: 30px;
      text-align: center
    }
  }
}
.server-advantage:hover {
  box-shadow: 0 0 40px 0 #e1e6f1;
}
</style>