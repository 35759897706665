<template>
  <div class="bottom-info">
    <div class="bottom-info-wrap">
      <div class="image"></div>
      <div class="text">
        <div class="left">
          <div class="info">公司</div>
          <div class="info click" @click="$router.push('/about')">关于我们</div>
        </div>
        <div class="right">
          <div class="info">邮箱：support@51guoxin.com</div>
          <div class="info">电话：13510802008</div>
          <div class="info">
            地址：深圳市南山区沙河街道高发社区侨香路4088号绿景美景广场310
          </div>
        </div>
      </div>
    </div>
    <div class="icp-info">
      <div class="police"></div>
      <div class="icp-text">
        © 2021深圳市国信网服技术有限公司<span @click="toICP"
          >粤ICP备2021031520号-1</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottom-info",
  methods: {
    toICP() {
      window.open("https://beian.miit.gov.cn");
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.bottom-info {
  display: flex;
  justify-content: center;
  color: white;
  height: 263px;
  position: relative;
  @media screen and (max-width: 1200px) {
    height: 127.5px;
  }
  .bottom-info-wrap {
    height: 243px;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1200px) {
      max-width: 92%;
      height: 121px;
    }
    .image {
      width: 209px;
      height: 100px;
      margin-right: 278px;
      @include bg-image("../../assets/Index/logo2");
      @media screen and (max-width: 1200px) {
        width: 104.5px;
        height: 50px;
        margin-right: 45.5px;
      }
    }
    .text {
      color: #15224a;
      font-size: 16px;
      font-weight: 400;
      display: flex;
      .left {
        .click {
          cursor: pointer;
        }
        .click:hover {
          color: #145cf6;
        }
        @media screen and (max-width: 1200px) {
          display: none;
        }
      }
      .right {
        margin-left: 119px;
        @media screen and (max-width: 1200px) {
          margin-left: 0;
          font-size: 12px;
        }
      }
      .info {
        margin-bottom: 15px;
        @media screen and (max-width: 1200px) {
          margin-bottom: 10px;
        }
      }
    }
  }
  .icp-info {
    color: #15224a;
    position: absolute;
    bottom: 10px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-content: center;
    @media screen and (max-width: 1200px) {
      bottom: 5px;
    }
    @media screen and (max-width: 374px) {
      display: none;
    }
    .police {
      width: 14px;
      height: 16px;
      @include police("../../common/image/police.png");
    }
    .icp-text {
      margin-left: 10px;
      @media screen and (max-width: 1200px) {
        margin-left: 5px;
      }
      span {
        cursor: pointer;
      }
    }
  }
}
</style>