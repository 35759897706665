<template>
  <div class="product-intro">
    <div class="product-intro-wrap">
      <div class="image" :id="image"></div>
      <div class="text" data-aos="fade-up" data-aos-duration="500">
        <div class="title">
          <slot name="title"></slot>
        </div>
        <div class="content">
          <slot name="content"></slot>
        </div>
        <div class="button" @click="$router.push(href)">了解详情</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product-intro",
  props: {
    image: {
      type: String,
    },
    href: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.product-intro {
  height: 620px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 812px) {
    height: 100%;
  }
  .product-intro-wrap {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1200px) {
      max-width: 100%;
      flex-direction: column;
      align-items: center;
    }
    .image {
      @media screen and (max-width: 1200px) {
        margin-top: 30px;
        margin-bottom: 50px;
      }
    }
    #network {
      width: 632px;
      height: 400px;
      @include bg-image("../../assets/Index/network");
      @media screen and (max-width: 812px) {
        width: 84.2%;
        height: 0;
        padding-bottom: 53.3%;
      }
    }
    #desktop {
      width: 562px;
      height: 400px;
      @include bg-image("../../assets/Index/desktop");
      @media screen and (max-width: 812px) {
        width: 74.9%;
        height: 0;
        padding-bottom: 53.3%;
      }
    }
    #server {
      width: 566px;
      height: 419px;
      @include bg-image("../../assets/Index/server");
      @media screen and (max-width: 812px) {
        width: 75.4%;
        height: 0;
        padding-bottom: 55.8%;
      }
    }
    #system {
      width: 486px;
      height: 439px;
      @include bg-image("../../assets/Index/system");
      @media screen and (max-width: 812px) {
        width: 64.8%;
        height: 0;
        padding-bottom: 58.5%;
      }
    }
    .text {
      @media screen and (max-width: 1200px) {
        align-self: flex-start;
        margin-left: 25px;
      }
      .title {
        line-height: 52px;
        color: #15224a;
        font-size: 36px;
        margin-bottom: 34px;
        @media screen and (max-width: 1200px) {
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 17px;
        }
        > span {
          display: block;
          @media screen and (max-width: 1200px) {
            display: inline-block;
          }
        }
      }
      .content {
        line-height: 32px;
        color: #5e7290;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 33px;
        @media screen and (max-width: 1200px) {
          font-size: 10px;
          transform: scale(0.83);
          transform-origin: 0 0;
          line-height: 21.7px;
          margin-bottom: 10.3px;
          width: 120%;
        }
      }
      .button {
        width: 140px;
        height: 40px;
        line-height: 40px;
        color: white;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        background-color: #145cf6;
        border-radius: 4px;
        cursor: pointer;
        @media screen and (max-width: 1200px) {
          width: 119px;
          height: 34px;
          font-size: 13.5px;
          line-height: 34px;
          border-radius: 2px;
          margin-bottom: 31px;
        }
      }
      .button:hover {
        background-color: #0b53ec;
      }
    }
  }
}
.product-intro:nth-of-type(odd) .product-intro-wrap {
  .image {
    margin-right: 88px;
    @media screen and (max-width: 1200px) {
      margin-right: 0;
    }
  }
}
.product-intro:nth-of-type(even) {
  background-color: #f8faff;
  .product-intro-wrap {
    flex-direction: row-reverse;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
    .image {
      margin-left: 88px;
      @media screen and (max-width: 1200px) {
        margin-left: 0;
      }
    }
  }
}
</style>