<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
};
</script>

<style scoped lang="scss">
html,
body,
#app {
  height: 100%;
  min-width: 1350px;
  @media screen and (max-width: 1350px) {
    min-width: 0;
    max-width: 100%;
  }
}
body {
  margin: 0 !important;
}
</style>
