import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./router.js";
import MetaInfo from "vue-meta-info";
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
import App from "./App.vue";

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(MetaInfo);
AOS.init({ once: true });

export const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

new Vue({
  router,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount("#app");
