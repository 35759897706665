<template>
  <div class="desktop">
    <top-bar active="products"></top-bar>
    <banner
      image="desktop"
      title="虚拟桌面"
      content="作为新的桌⾯基础架构，桌⾯虚拟化技术可解决传统IT架构下存在的数据缺乏统⼀管理、资源利⽤率低下、桌⾯环境复杂等问题，具备⾼效运维、灵活办公、数据安全等诸多优点。"
    ></banner>
    <div class="sub-content-list">
      <sub-content title="传统IT架构面临问题">
        <div class="desktop-feature-list">
          <desktop-feature image="price" title="成本">
            资源利用率低<br />
            一次性设备投入成本高
          </desktop-feature>
          <desktop-feature image="safety" title="安全">
            数据缺乏统一管理<br />
            信息易泄露
          </desktop-feature>
          <desktop-feature image="management" title="管理">
            桌面环境复杂<br />
            设备分散维护难度大
          </desktop-feature>
        </div>
      </sub-content>
      <sub-content title="产品优势" bgcolor="#F8FAFF">
        <div class="desktop-advantage-list">
          <desktop-advantage
            image="product-safety"
            title="企业文件、数据更安全"
            content="所有的文件自创建开始便存在于服务器上，公司可以集中式的定期备份，预防意外损坏或恶意的删除或外传等"
          ></desktop-advantage>
          <desktop-advantage
            image="center"
            title="集中配置及管控"
            content="所有的终端通过配置服务器进行集中式的配置，零管理。管理员可以在任意位置远程协助用户"
          ></desktop-advantage>
          <desktop-advantage
            image="danger"
            title="减少企业软件版权风险"
            content="软件版权如此昂贵，尤其是Solidworks等专业软件，让几十用户同时共用一款软件，减少90%以上软件版权成本"
          ></desktop-advantage>
          <desktop-advantage
            image="remote"
            title="5G移动办公时代"
            content="通过简单的配置，便可以让公司的员工在出差或者在家时也能够连接公司的桌面办公，且无需担心相应的文件外泄安全"
          ></desktop-advantage>
          <desktop-advantage
            image="usb"
            title="USB外设的管理"
            content="管理员可以在服务器上配置相应的策略来允许特定的用户是否可以使用U盘等移动外设"
          ></desktop-advantage>
          <desktop-advantage
            image="performance"
            title="更佳的性能体验"
            content="7*传统用户仅能够使用自己主机资源，而产品用户则可以使用所有闲置的硬件资源，使用体验更佳"
          ></desktop-advantage>
          <desktop-advantage
            image="green"
            title="绿色节能"
            content="7*终端仅5W的功能，每台云终端每年相比较传统PC要节省400元电费，帮助企业节能减负"
          ></desktop-advantage>
          <desktop-advantage
            image="life-cycle"
            title="更长的生命周期"
            content="7*传统的PC每3-5年便需做相应的汰换，而终端彻底告别升级换代死循环，通常云终端可以使用7-10年或更久"
          ></desktop-advantage>
        </div>
      </sub-content>
      <sub-content title="虚拟桌面架构">
        <div id="virtual-desktop-image"></div>
      </sub-content>
      <sub-content title="应用场景" bgcolor="#F8FAFF">
        <div class="desktop-scene-list">
          <desktop-scene
            image="office"
            title="政企办公"
            content="极简IT新架构，助力政企快速上云。桌面快速部署，数据与应用存储在云端，支持USB外设、打印机等"
          ></desktop-scene>
          <desktop-scene
            image="design"
            title="视觉设计"
            content="完美支持大部分专业制作设计软件，满足设计师项目制作的需求，平行创作随时随地查看修改文件"
          ></desktop-scene>
          <desktop-scene
            image="video"
            title="影视制作"
            content="支持多屏协同、数据管控、文件共享协作、USB白名单、网络管理等功能。提供专业的高性能图形工作站与算力"
          ></desktop-scene>
          <desktop-scene
            image="education"
            title="教育培训"
            content="按照不同教学场景需求定制化机器配置，支持多媒体播放，多种GPU显卡可选"
          ></desktop-scene>
        </div>
      </sub-content>
      <sub-content title="用户评价">
        <div class="evaluation-list">
          <evaluation
            image="golden-seattle"
            title="金雅图"
            content="电脑能够很好地解决异地办公的需求，通过为我们总部IT配备企业主账号，实现统一部署和管理，为我们全国各处项目落地提供了很大的便捷。图形工作站性能强大、服务稳定，疫情期间，我们设计部门同事在家使用低配电脑登录就可轻松使用高配云电脑进行远程办公，为我们项目进度提供了安全保障。"
          ></evaluation>
          <evaluation
            image="rayclouds"
            title="雷云光电"
            content="工作站满足了我司动画渲染时所需机器的数量与配置，使我们在限定时间内高效完成工作任务。同时，可提高异地办公及低配电脑办公的工作效率，在本地电脑渲染所需时间较长，但在云工作站上渲染，可让实体机待机、关机，也可在异地的电脑上查看渲染进度。的客服与技术人员也能及时提供相关技术支持，非常值得信赖。"
          ></evaluation>
          <evaluation
            image="byte-dance"
            title="字节跳动"
            content="在疫情期间，我们使用了独立显卡配置的机器，能够很好地满足公司设计师对软件的使用需求，保障了设计团队整体项目顺利推进，同时也使项目团队协作也更加便捷。"
          ></evaluation>
          <evaluation
            image="pxy-technologies"
            title="平行云 "
            content="多地部署的以GPU资源为核心的IDC数据中心，与我们LarkXR产品有机结合，解决了XR内容碎片化和不易传播分享的问题，赋能客户轻松实现XR云化转型，高效使能企业的Cloud XR业务。特别在疫情期间，双方合作为百余所高校提供了多学科的虚拟仿真实验教学资源线上共享服务，积极响应“停课不停学”的号召，得到伙伴们的一致好评。"
          ></evaluation>
        </div>
      </sub-content>
    </div>
    <contact-us></contact-us>
    <bottom-info></bottom-info>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import Banner from "../components/Banner.vue";
import SubContent from "../components/SubContent.vue";
import DesktopFeature from "./components/DesktopFeature.vue";
import DesktopAdvantage from "./components/DesktopAdvantage.vue";
import DesktopScene from "./components/DesktopScene.vue";
import Evaluation from "./components/Evaluation.vue";
import ContactUs from "../components/ContactUs.vue";
import BottomInfo from "../components/BottomInfo.vue";
export default {
  name: "desktop",
  components: {
    TopBar,
    Banner,
    SubContent,
    DesktopFeature,
    DesktopAdvantage,
    DesktopScene,
    Evaluation,
    ContactUs,
    BottomInfo,
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.desktop {
  .sub-content-list {
    .desktop-feature-list {
      display: flex;
      width: 1077px;
      justify-content: space-between;
      margin-bottom: 104px;
      @media screen and (max-width: 1077px) {
        max-width: 100%;
        margin-bottom: 48.5px;
        margin-top: -30px;
      }
    }
    .desktop-advantage-list {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 78px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
        margin-top: -45px;
      }
    }
    #virtual-desktop-image {
      width: 961px;
      height: 724px;
      @include bg-image("../../assets/products/Desktop/virtualDesktop");
      margin-bottom: 95px;
      @media screen and (max-width: 1200px) {
        margin: -40px auto 47.5px;
        width: 92.1%;
        height: 0;
        padding-bottom: 69.3%;
      }
    }
    .desktop-scene-list {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 88px;
      @media screen and (max-width: 1200px) {
        max-width: calc(100% - 25px);
        margin: -40px auto 46px;
        flex-wrap: wrap;
      }
    }
    .evaluation-list {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin-bottom: 114px;
      @media screen and (max-width: 1200px) {
        max-width: calc(100% - 30px);
        flex-wrap: wrap;
        margin: -30px auto 48px;
      }
    }
  }
}
</style>