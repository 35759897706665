<template>
  <div class="render-requirement">
    <div class="image" :id="image"></div>
    <div class="text">
      <div class="title">{{ title }}</div>
      <div class="content" v-if="content">{{ content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "render-requirement",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.render-requirement {
  width: 590px;
  background-color: white;
  height: 160px;
  display: flex;
  box-shadow: 0 0 30px 0 #eef3ff;
  margin-bottom: 20px;
  .image {
    margin: 36px 0 0 28px;
    width: 50px;
    height: 50px;
  }
  #movie {
    @include bg-image("../../../assets/solutions/RenderCloud/movie");
  }
  #cloud {
    @include bg-image("../../../assets/solutions/RenderCloud/cloud");
  }
  #batch {
    @include bg-image("../../../assets/solutions/RenderCloud/batch");
  }
  #view {
    @include bg-image("../../../assets/solutions/RenderCloud/view");
  }
  .text {
    margin: 36px 0 0 24px;
    .title {
      color: #15224a;
      font-weight: 400;
      font-size: 20px;
      margin-bottom: 15px;
    }
    .content {
      color: #5e7290;
      font-weight: 400;
      font-size: 14px;
      width: 451px;
      line-height: 22px;
    }
  }
}
.price:hover {
  box-shadow: 0 0 30px 0 #dee3ee;
}
</style>