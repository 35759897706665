import { __STRAPI__HOSTNAME__, __STRAPI__PORT__ } from "./constants";

const coverImgPattern = "!\\[.*\\]\\((.*?)\\)";
const coverImgRichTextPattern = /<img [^>]*src=['"]([^'"]+)[^>]*>/;

export default class ContentService {
  static getCoverImg(content) {
    const imgCoverRegex = new RegExp(coverImgPattern);
    let matchResult;
    if (content) {
      matchResult = content.match(imgCoverRegex);
      if (!matchResult) {
        matchResult = [];
        content.replace(coverImgRichTextPattern, (match, capture) => {
          matchResult[1] = capture;
        });
      }
    }
    return matchResult
      ? process.env.NODE_ENV === "production"
        ? matchResult[1]
        : location.protocol +
          "//" +
          __STRAPI__HOSTNAME__ +
          __STRAPI__PORT__ +
          matchResult[1]
      : "";
  }
  static removeCoverImg(content) {
    const imgCoverRegex = new RegExp(coverImgPattern);
    const replaceResult = content.replace(imgCoverRegex, "");
    return replaceResult || "";
  }
  static prependPrefixToImgSrc(markdown) {
    const pattern = /!\[.*?\]\((.*?)\)/g;
    const matches = markdown.match(pattern);

    if (matches) {
      matches.forEach((match) => {
        const src = match.replace(/^!\[.*?\]\((.*?)\)$/, "$1");
        const newSrc =
          process.env.NODE_ENV === "production"
            ? src
            : `${location.protocol}//${__STRAPI__HOSTNAME__}${__STRAPI__PORT__}${src}`;
        markdown = markdown.replace(match, match.replace(src, newSrc));
      });
    }

    return markdown;
  }
  static getAbstract(content) {
    return content
      ? content
          .replace(/(\*|\r\n|__|#|<.*?>|!\[.*\]\(.*?\)!?)/g, "")
          .replace(/&nbsp;/g, " ")
          .replace(/\s?\[(.*?)\]\(.*?\)/g, " $1")
      : "";
  }
  static filterNews(obj) {
    const regImg = /!\[.*\]\(\/\/images.*?\)|<video[\s\S]*?\/video>|<img[\s|S]*?\/img>/g;
    const regSymbol = /(\*|\r\n|__|#|<.*?>|!\[.*\]\(.*?\)!?)/g;
    return obj
      .replace(regImg, "")
      .replace(regSymbol, "")
      .replace(/&nbsp;/g, " ")
      .replace(/\s\[(.*?)\]\(.*?\)/g, "$1");
  }
  static getFirstLevelNews(catalogue) {
    let firstLevelDirectory = catalogue[0];
    while (!firstLevelDirectory.isFirstLevelDirectory) {
      firstLevelDirectory = firstLevelDirectory.supportcatalogue[0];
    }
    return firstLevelDirectory.title;
  }
  static getFirstNews(sortCatalogue) {
    let firstDirectory = sortCatalogue[0];
    while (!!firstDirectory && !!firstDirectory.isCatalogue) {
      firstDirectory = firstDirectory.children[0];
    }
    return firstDirectory;
  }
}
