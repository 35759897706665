<template>
  <div class="certification">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
    <div class="content" v-if="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "certification",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.certification {
  width: 317px;
  .image {
    margin-bottom: 32px;
  }
  #cts {
    width: 103px;
    height: 102px;
    @include bg-image("../../../assets/solutions/CtsGmsNts/cts");
  }
  #gms {
    width: 92px;
    height: 92px;
    margin: 5px 0 37px 0;
    @include bg-image("../../../assets/solutions/CtsGmsNts/gms");
  }
  #nts {
    width: 100px;
    height: 84px;
    margin: 9px 0 41px 0;
    @include bg-image("../../../assets/solutions/CtsGmsNts/nts");
  }
  .title {
    color: #182940;
    font-weight: 400;
    font-size: 30px;
    margin-bottom: 30px;
  }
  .content {
    color: #5E7290;
    font-weight: 400;
    font-size: 14px;
    width: 317px;
    line-height: 24px;
  }
}
</style>