<template>
  <div class="challenge">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
    <div class="content" v-if="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "challenge",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.challenge {
  display: flex;
  flex-direction: column;
  align-items: center;
  .image {
    margin-bottom: 34px;
  }
  #expensive {
    width: 103px;
    height: 103px;
    @include bg-image("../../../assets/solutions/Ai/expensive");
  }
  #computing {
    width: 103px;
    height: 103px;
    @include bg-image("../../../assets/solutions/Ai/computing");
  }
  #unused {
    width: 87px;
    height: 103px;
    @include bg-image("../../../assets/solutions/Ai/unused");
  }
  .title {
    color: #182940;
    font-weight: 400;
    font-size: 30px;
    margin-bottom: 26px;
  }
  .content {
    text-align: center;
    color: #5E7290;
    font-weight: 400;
    font-size: 16px;
    width: 351px;
    line-height: 24px;
  }
}
</style>