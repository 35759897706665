<template>
  <div class="top-bar" :class="{ 'white-top-bar': theme === 'white' }">
    <div class="top-bar-wrap">
      <div class="left">
        <div
          class="logo"
          :class="{ 'white-logo': theme === 'white' }"
          @click="$router.push('/')"
        ></div>
      </div>
      <div class="right">
        <div
          class="location-list"
          :class="{ 'white-location-list': theme === 'white' }"
        >
          <div
            class="location"
            :class="{ active: active === 'index' }"
            @click="$router.push('/')"
          >
            首页
          </div>
          <div
            class="location subtab"
            :class="{ active: active === 'products' }"
          >
            产品
            <ul class="subtab-list">
              <li @click="$router.push('/products/network')">SD-WAN</li>
              <li @click="$router.push('/products/desktop')">虚拟桌面</li>
              <li @click="$router.push('/products/server')">GPU云服务器</li>
              <li @click="$router.push('/products/fastsync')">飞安达传输</li>
              <li @click="$router.push('/products/cdn')">CDN</li>
            </ul>
          </div>
          <div
            class="location subtab"
            :class="{ active: active === 'solutions' }"
          >
            解决方案
            <ul class="subtab-list">
              <li @click="$router.push('/solutions/game')">云游戏</li>
              <li @click="$router.push('/solutions/ai')">人工智能</li>
              <li @click="$router.push('/solutions/education')">教育行业</li>
              <li @click="$router.push('/solutions/ctsgmsnts')">CTS/GMS/NTS</li>
              <li @click="$router.push('/solutions/streaming')">流媒体</li>
              <li @click="$router.push('/solutions/rendercloud')">渲染云</li>
            </ul>
          </div>
          <div
            class="location"
            :class="{ active: active === 'about' }"
            @click="$router.push('/about')"
          >
            关于
          </div>
          <div
            class="location"
            :class="{ active: active === 'news' }"
            @click="$router.push('/news/list')"
          >
            新闻
          </div>
          <div
            v-if="
              location.pathname.includes('solutions') ||
              location.pathname.includes('about')
            "
            class="mobile-menu-black-button"
            @click.passive="isCollapse = false"
          ></div>
          <div
            v-else
            class="mobile-menu-button"
            @click.passive="isCollapse = false"
          ></div>
          <div
            v-if="!isCollapse"
            class="mobile-menu-background"
            @click.passive="isCollapse = true"
          >
            <transition name="slide-fade">
              <div class="mobile-menu" @click.stop>
                <div class="close-button" @click.passive="isCollapse = true">
                  ×
                </div>
                <div class="mobile-location" @click="$router.push('/')">
                  首页
                </div>
                <div
                  class="mobile-location"
                  @click="isCollapseProduct = !isCollapseProduct"
                >
                  产品
                  <div
                    class="mobile-arrow"
                    :style="{
                      transform: isCollapseProduct ? '' : 'rotate(180deg)',
                    }"
                  ></div>
                  <ul class="subtab-menu" v-if="!isCollapseProduct">
                    <li @click="$router.push('/products/network')">SD-WAN</li>
                    <li @click="$router.push('/products/desktop')">虚拟桌面</li>
                    <li @click="$router.push('/products/server')">
                      GPU云服务器
                    </li>
                    <li @click="$router.push('/products/fastsync')">
                      飞安达传输
                    </li>
                    <li @click="$router.push('/products/cdn')">CDN</li>
                  </ul>
                </div>
                <div
                  class="mobile-location"
                  @click="isCollapseSolution = !isCollapseSolution"
                >
                  解决方案
                  <div
                    class="mobile-arrow"
                    :style="{
                      transform: isCollapseSolution ? '' : 'rotate(180deg)',
                    }"
                  ></div>
                  <ul class="subtab-menu" v-if="!isCollapseSolution">
                    <li @click="$router.push('/solutions/game')">云游戏</li>
                    <li @click="$router.push('/solutions/ai')">人工智能</li>
                    <li @click="$router.push('/solutions/education')">
                      教育行业
                    </li>
                    <li @click="$router.push('/solutions/ctsgmsnts')">
                      CTS/GMS/NTS
                    </li>
                    <li @click="$router.push('/solutions/streaming')">
                      流媒体
                    </li>
                    <li @click="$router.push('/solutions/rendercloud')">
                      渲染云
                    </li>
                  </ul>
                </div>
                <div class="mobile-location" @click="$router.push('/about')">
                  关于
                </div>
                <div
                  class="mobile-location"
                  @click="$router.push('/news/list')"
                >
                  新闻
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "top-bar",
  props: {
    active: {
      type: String,
    },
    theme: {
      type: String,
      default: "blue",
    },
  },
  data() {
    return {
      location: window.location,
      isCollapse: true,
      isCollapseProduct: true,
      isCollapseSolution: true,
    };
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.top-bar {
  width: 100%;
  height: 60px;
  background-color: #07235f;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 812px) {
    height: 40px;
  }
  .top-bar-wrap {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 812px) {
      width: calc(100% - 25px);
    }
    .left {
      flex-basis: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      .logo {
        cursor: pointer;
        width: 165px;
        height: 40px;
        @include bg-image("../../assets/Index/logo");
        @media screen and (max-width: 812px) {
          width: 102.5px;
          height: 25px;
        }
      }
      .white-logo {
        @include bg-image("../../assets/Index/logoWhite");
      }
    }
    .right {
      flex-basis: 50%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      .location-list {
        display: flex;
        height: 100%;
        position: relative;
        .location {
          flex-shrink: 0;
          flex-grow: 0;
          position: relative;
          cursor: pointer;
          height: 100%;
          line-height: 60px;
          color: white;
          margin-right: 50px;
          @media screen and (max-width: 812px) {
            display: none;
          }
        }
        .location:last-child {
          margin-right: 0;
        }
        .active::after {
          content: "";
          bottom: 0;
          left: -2px;
          position: absolute;
          width: calc(100% + 4px);
          height: 2px;
          background-color: white;
        }
        .subtab-list {
          display: none;
          top: 60px;
          left: 50%;
          transform: translateX(-50%);
          position: absolute;
          width: 160px;
          background-color: white;
          border-radius: 20px;
          z-index: 2;
          padding: 15.5px 0;
          border: 1px solid grey;
          li {
            padding-left: 25px;
            font-size: 16px;
            line-height: 35px;
            font-weight: 400;
            color: #15224a;
          }
          li:hover {
            color: #145cf6;
          }
        }
        .subtab:hover .subtab-list,
        .subtab-list:hover {
          display: block;
        }
        .mobile-menu-button {
          width: 16px;
          height: 16.5px;
          display: none;
          cursor: pointer;
          @include bg-image("../../common/image/menu");
          @media screen and (max-width: 812px) {
            display: block;
          }
        }
        .mobile-menu-black-button {
          width: 16px;
          height: 16.5px;
          display: none;
          cursor: pointer;
          @include bg-image("../../common/image/menu-black");
          @media screen and (max-width: 812px) {
            display: block;
          }
        }
        .mobile-menu-background {
          position: fixed;
          background-color: rgba(0, 0, 0, 0.3);
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 3;
          .mobile-menu {
            position: fixed;
            top: 0;
            right: 0;
            height: calc(100% - 92px);
            overflow-y: auto;
            background-color: white;
            width: 220px;
            z-index: 10;
            padding: 46px 0;
            .close-button {
              position: absolute;
              right: 7.5px;
              font-size: 30px;
              top: 0;
            }
            .mobile-location {
              display: block;
              font-size: 18px;
              margin-top: 45px;
              padding-left: 26.5px;
              color: #333333;
              position: relative;
              .mobile-arrow {
                position: absolute;
                width: 15px;
                height: 9px;
                right: 21px;
                top: 8px;
                @include bg-image("../../common/image/mobile-arrow");
              }
            }
            .subtab-menu {
              margin-top: 27.5px;
              border: 0;
              li {
                font-size: 15px;
                color: #666666;
                margin-bottom: 22px;
              }
            }
            .subtab-menu:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .white-location-list {
        .location {
          color: #15224a;
        }
        .active::after {
          bottom: 7px;
          background-color: #145cf6;
        }
      }
    }
  }
}
.white-top-bar {
  background-color: white;
}
.slide-fade-enter-active {
  transition: all 0.1s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>