<template>
  <div class="point">
    <div class="image" :id="image"></div>
    <div class="text">
      <div class="title">{{ title }}</div>
      <div class="content" v-if="content">{{ content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "point",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.point {
  background-color: white;
  display: flex;
  margin-bottom: 61px;
  .image {
    margin-right: 23px;
  }
  #stable {
    width: 60px;
    height: 60px;
    @include bg-image("../../../assets/solutions/Game/stable");
  }
  #convenient {
    width: 62px;
    height: 63px;
    @include bg-image("../../../assets/solutions/Game/convenient");
  }
  #super {
    width: 60px;
    height: 60px;
    @include bg-image("../../../assets/solutions/Game/super");
  }
  .text {
    .title {
      color: #3E495A;
      font-size: 20px;
      margin-bottom: 12px;
    }
    .content {
      color: #3E495A;
      font-weight: 400;
      font-size: 14px;
      width: 272px;
      line-height: 21px;
    }
  }
}
.point:last-child {
  margin-bottom: 0;
}
</style>