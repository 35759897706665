<template>
  <div
    class="cdn-feature"
    :class="{ 'active-line': nowActive === active }"
    @click="$emit('nowactive', active)"
  >
    <div class="title" :class="{ active: nowActive === active }">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "cdn-feature",
  props: {
    title: {
      type: String,
    },
    active: {
      type: Number,
    },
    nowActive: {
      type: Number,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.cdn-feature {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  .title {
    margin-bottom: 24px;
    color: #15224a;
    font-size: 16px;
    font-weight: 400;
  }
  .active {
    color: #145cf6;
  }
}
.active-line::after {
  position: absolute;
  bottom: 0;
  left: -2px;
  content: "";
  height: 4px;
  width: calc(100% + 4px);
  background-color: #145cf6;
}
</style>