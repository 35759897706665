<template>
  <div class="ai">
    <top-bar active="solutions" theme="white"></top-bar>
    <solutions-banner
      image="ai"
      content="GPUCAT基于异构计算提供超强浮点计算能力服务，提供端到端的深度学习资源，缩短训练环境部署时间，为客户提供更强大的AI算力支持，加速AI落地应用。"
    >
      人工智能应用
    </solutions-banner>
    <div class="sub-content-list">
      <sub-content title="面临挑战" bgcolor="#F8FAFF">
        <div class="challenge-list">
          <challenge
            image="expensive"
            title="成本高"
            content="训练任务周期长短不可控，前期需要投入大量资金采购硬件设备或云主机。"
          >
          </challenge>
          <challenge
            image="computing"
            title="算力需求高"
            content="人工智能需要借助深度学习的类神经演算法才能加深AI未来的应用，而这一切都需要大量的并行运算能力才能当做深度学习训练模型使用。"
          >
          </challenge>
          <challenge
            image="unused"
            title="资源闲置"
            content="训练任务结束后，采购的服务器被闲置，资源无法得到有效利用。"
          >
          </challenge>
        </div>
      </sub-content>
      <sub-content title="架构部署">
        <div class="structure-wrap">
          <div id="structure-image"></div>
          <div class="point-wrap">
            <div class="point-title">适用场景</div>
            <div class="point-list">
              <ai-point image="identify" title="AI识别"></ai-point>
              <ai-point image="train" title="AI实训平台"></ai-point>
              <ai-point image="model" title="算法模型训练"></ai-point>
              <ai-point image="open" title="AI开放平台"></ai-point>
            </div>
          </div>
        </div>
      </sub-content>
      <sub-content title="方案优势" bgcolor="#F8FAFF">
        <div class="ai-advantage-list">
          <ai-advantage
            image="price"
            title="租期弹性，控制成本"
            content="提供GPU和CPU弹性裸金属服务器，支持根据自身需求灵活搭配选择，按月付费，租期弹性灵活。"
          ></ai-advantage>
          <ai-advantage
            image="quick"
            title="算力资源快速获取"
            content="支持多种异构系统混合使用，自研调度器木牛（Munu）分布式管理系统可同时管理5000台GPU节点，无缝对接AI算力资源。"
          ></ai-advantage>
          <ai-advantage
            image="performance"
            title="高效计算，性能卓越"
            content="GPUCAT能提供人工智能所需的并行运算，并将其发挥到极致，GPUCAT从容应对高性能计算需求，赋予人工智能更好的发展。"
          ></ai-advantage>
        </div>
      </sub-content>
    </div>
    <contact-us></contact-us>
    <bottom-info></bottom-info>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import SolutionsBanner from "../components/SolutionsBanner.vue";
import SubContent from "../components/SubContent.vue";
import Challenge from "./components/Challenge.vue";
import AiPoint from "./components/AiPoint.vue";
import AiAdvantage from "./components/AiAdvantage.vue";
import ContactUs from "../components/ContactUs.vue";
import BottomInfo from "../components/BottomInfo.vue";
export default {
  name: "ai",
  components: {
    TopBar,
    SolutionsBanner,
    SubContent,
    Challenge,
    AiPoint,
    AiAdvantage,
    ContactUs,
    BottomInfo,
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.ai {
  .sub-content-list {
    .challenge-list {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 108px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
    .structure-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 94px;
      #structure-image {
        width: 840px;
        height: 321px;
        background-image: url("../../assets/solutions/Ai/structure2.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        /* @include bg-image("../../assets/solutions/Ai/structure"); */
      }
      .point-wrap {
        margin-left: 78px;
        margin-bottom: -56px;
        .point-title {
          color: #145cf6;
          font-size: 20px;
          margin-bottom: 46px;
          font-weight: 400;
          display: block;
        }
        .point-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 298px;
          margin-left: -16px;
        }
      }
    }
    .ai-advantage-list {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin-bottom: 91px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
  }
}
</style>