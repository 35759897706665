<template>
  <div class="ai-point">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "ai-point",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.ai-point {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin-bottom: 56px;
  width: 120px;
  .image {
    margin-bottom: 22px;
  }
  #identify {
    width: 61px;
    height: 60px;
    margin: 0.5px 0 22.5px 0;
    @include bg-image("../../../assets/solutions/Ai/identify");
  }
  #train {
    width: 54px;
    height: 61px;
    @include bg-image("../../../assets/solutions/Ai/train");
  }
  #model {
    width: 54px;
    height: 61px;
    @include bg-image("../../../assets/solutions/Ai/model");
  }
  #open {
    width: 60px;
    height: 48px;
    margin: 6.5px 0 28.5px 0;
    @include bg-image("../../../assets/solutions/Ai/open");
  }
  .title {
    color: #3e495a;
    font-size: 16px;
    font-weight: 400;
  }
}
</style>