<template>
  <div class="desktop-feature">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "desktop-feature",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.desktop-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1200px) {
    flex-basis: 33.3%;
  }
  .image {
    margin-bottom: 34px;
    @media screen and (max-width: 1200px) {
      margin-bottom: 17px;
    }
  }
  #price {
    width: 138px;
    height: 149px;
    @include bg-image("../../../assets/products/Desktop/price");
    @media screen and (max-width: 1200px) {
      width: 69px;
      height: 74.5px;
    }
  }
  #safety {
    width: 136px;
    height: 149px;
    @include bg-image("../../../assets/products/Desktop/safety");
    @media screen and (max-width: 1200px) {
      width: 68px;
      height: 74.5px;
    }
  }
  #management {
    width: 139px;
    height: 144px;
    @include bg-image("../../../assets/products/Desktop/management");
    @media screen and (max-width: 1200px) {
      width: 69.5px;
      height: 74.5px;
    }
  }
  .title {
    margin-bottom: 30px;
    color: #182940;
    font-size: 30px;
    font-weight: 400;
    @media screen and (max-width: 1200px) {
      font-size: 15px;
      margin-bottom: 13.5px;
    }
  }
  .content {
    text-align: center;
    line-height: 24px;
    color: #5e7290;
    font-size: 16px;
    font-weight: 400;
    @media screen and (max-width: 1200px) {
      font-size: 10px;
      transform: scale(0.83);
    }
  }
}
</style>