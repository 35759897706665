<template>
  <div class="solutions-banner">
    <div class="image" :id="image">
      <div
        class="solutions-banner-wrap"
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <div class="title">
          <slot></slot>
        </div>
        <div class="content" v-if="content">{{ content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "solutions-banner",
  props: {
    content: {
      type: String,
    },
    image: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.solutions-banner {
  height: 520px;
  color: white;
  .image {
    height: 100%;
    display: flex;
    justify-content: center;
    .solutions-banner-wrap {
      width: 1200px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
      .title {
        line-height: 61px;
        font-weight: bold;
        font-size: 48px;
      }
      .content {
        font-weight: 400;
        font-size: 16px;
        margin-top: 31px;
        width: 462px;
        line-height: 26px;
      }
    }
  }
  #game {
    @include bg-image-raw("../../assets/solutions/Game/banner");
  }
  #ai {
    @include bg-image-raw("../../assets/solutions/Ai/banner");
  }
  #education {
    @include bg-image-raw("../../assets/solutions/Education/banner");
  }
  #cts-gms-nts {
    @include bg-image-raw("../../assets/solutions/CtsGmsNts/banner");
  }
  #streaming {
    @include bg-image-raw("../../assets/solutions/Streaming/banner");
  }
  #render-cloud {
    @include bg-image-raw("../../assets/solutions/RenderCloud/banner");
  }
}
</style>