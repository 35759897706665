<template>
  <div class="render-cloud">
    <top-bar active="solutions" theme="white"></top-bar>
    <solutions-banner
      image="render-cloud"
      content="渲染云解决方案是基于国信网服云计算技术的渲染服务，利用自建多个云服务边缘节点的能力，为用户提供离线和实时渲染服务。它能够解决用户在内容创作和运营过程中遇到的终端算力不足、画面表现较差、初期采购成本高等问题，帮助用户将更多精力和资源投入到内容创作和运营中。渲染云具有高效、低成本、可伸缩等特点，同时提供灵活性和便利性，可以随着客户需求的变化而随时扩展，并通过互联网轻松访问，无需复杂的 IT 基础设施和维护工作。"
    >
      渲染云解决方案
    </solutions-banner>
    <div class="sub-content-list">
      <sub-content title="行业需求">
        <div class="requirement-list">
          <render-requirement
            image="movie"
            title="效果图&影视动画渲染"
            content="秒级上传本地效果图、影视动画至云端，上传文件兼容多种插件配置，一键分析作品、设置渲染参数、提交至云端，实现极速渲染，自动下载。"
          />
          <render-requirement
            image="cloud"
            title="Cloud XR云端渲染"
            content="一键拖拽Cloud XR应用，即可在多种终端和系统上运行。终端（系统）能打开浏览器即可访问各种交互式应用，同时解决传统受外部环境影响的限制问题。"
          />
          <render-requirement
            image="batch"
            title="批量同步渲染"
            content="支持同时上传多张效果图在云端，根据渲染集群算力，分配最优底层资源，同时渲染多张效果图。可大幅缩短渲染时间，提高渲染效率和渲染质量。"
          />
          <render-requirement
            image="view"
            title="灵活配置&实时监控"
            content="兼容市场上大部分主流渲染软件和插件，计算存储节点配置、码率等参数配置。实时查看渲染作业的提交时间、软件配置、渲染进度、渲染耗时、运行时间、成功率及云端存储空间使用率。"
          />
        </div>
      </sub-content>
      <sub-content title="应用场景" bgcolor="#F8FAFF">
        <div class="scene-wrap">
          <div class="scene">
            <div class="scene-pic"><div id="scene1"></div></div>
            <div class="scene-text">
              <div class="scene-title">3D交互式看车</div>
              <div class="scene-content">
                能够明显增强消费者对品牌的感知价值，提升汽车品牌竞争力；加深消费者对汽车的了解，加速决策过程，提升电子商务成单率。
              </div>
            </div>
          </div>
          <div class="scene">
            <div class="scene-pic"><div id="scene2"></div></div>
            <div class="scene-text">
              <div class="scene-title">VR看房</div>
              <div class="scene-content">
                打破被社交距离限制的看房模式，利用高沉浸感带来感官冲击，是房地产销售的利器。看房者既可以浏览房源的全貌，也可以步入房间查看细节，甚至实时体验打开抽屉、更换壁纸的效果。
              </div>
            </div>
          </div>
          <div class="scene">
            <div class="scene-pic"><div id="scene3"></div></div>
            <div class="scene-text">
              <div class="scene-title">数字化仿真教学</div>
              <div class="scene-content">
                基于实时渲染技术，可以在制造或医学教学场景中构建信息化、数字化的对象，通过采取与现代影像相结合的形式，获取学习对象的结构和数据信息。在云上搭建交互式仿真教学应用，提升教学质量。
              </div>
            </div>
          </div>
          <div class="scene">
            <div class="scene-pic"><div id="scene4"></div></div>
            <div class="scene-text">
              <div class="scene-title">智慧教育平台</div>
              <div class="scene-content">
                智慧教育平台是一种为职业院校提供高质量的云渲染、云存储及云计算功能的解决方案。解决移动终端计算和存储性能不足对XR教学带来的限制，让最低配的5G手机也能够获得高质量的XR教学体验。
              </div>
            </div>
          </div>
        </div>
      </sub-content>
      <sub-content title="方案架构">
        <div id="structure"></div>
      </sub-content>
      <sub-content title="方案优势" bgcolor="#F8FAFF">
        <div class="advantage-list">
          <render-advantage
            image="high-performance"
            title="高效渲染"
            content="多任务多机器同时渲染，极大提高渲染效率，场景一键优化辅助工具，省心省时更省钱；同时在软件方面，使用强大渲染算力网络，自动识别用户文件，高效的渲染算法，实现极速渲染可扩展性强。"
          />
          <render-advantage
            image="safe"
            title="安全可靠"
            content="计算模型和数据都部署在云端，内容存储在云端，终端仅显示实时交互的视频流，对于客户端来说这是一种“零安装”的解决方案；同时，用户与应用数据隔离，云平台自身也拥有多重保障。"
          />
          <render-advantage
            image="unify"
            title="统一管理"
            content="以高性能计算集群为基础，将海量的渲染任务交由网络中分布的多台服务器共同运算，由机器统一管理，资源利用率高。"
          />
          <render-advantage
            image="price"
            title="节约成本"
            content="渲染操作非常耗费硬件资源，对硬件要求很高。使用云渲染只需提供本地渲染素材，在云端完成渲染任务，节约在财力、物力、人力上的成本。"
          />
        </div>
      </sub-content>
    </div>
    <contact-us></contact-us>
    <bottom-info></bottom-info>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import SolutionsBanner from "../components/SolutionsBanner.vue";
import SubContent from "../components/SubContent.vue";
import RenderAdvantage from "./components/RenderAdvantage.vue";
import ContactUs from "../components/ContactUs.vue";
import BottomInfo from "../components/BottomInfo.vue";
import RenderRequirement from "./components/RenderRequirement.vue";
export default {
  name: "render-cloud",
  components: {
    TopBar,
    SolutionsBanner,
    SubContent,
    RenderAdvantage,
    ContactUs,
    BottomInfo,
    RenderRequirement,
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.render-cloud {
  .sub-content-list {
    .requirement-list {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 100px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
    .scene-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 120px;
      width: 1920px;
      .scene {
        display: flex;
        width: 100%;
        height: 460px;
        &:nth-child(2n + 1) {
          flex-direction: row;
        }
        &:nth-child(2n) {
          flex-direction: row-reverse;
          .scene-text {
            align-items: flex-end;
          }
        }
        .scene-pic {
          height: 100%;
          flex-basis: 50%;
          #scene1 {
            width: 100%;
            height: 100%;
            @include bg-image("../../assets/solutions/RenderCloud/scene1");
          }
          #scene2 {
            width: 100%;
            height: 100%;
            @include bg-image("../../assets/solutions/RenderCloud/scene2");
          }
          #scene3 {
            width: 100%;
            height: 100%;
            @include bg-image("../../assets/solutions/RenderCloud/scene3");
          }
          #scene4 {
            width: 100%;
            height: 100%;
            @include bg-image("../../assets/solutions/RenderCloud/scene4");
          }
        }
        .scene-text {
          flex-basis: calc(50% - 120px);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 60px;
          .scene-title {
            font-size: 30px;
            font-weight: bold;
            color: #15224a;
            margin-bottom: 24px;
            width: 540px;
          }
          .scene-content {
            font-size: 18px;
            font-weight: 400;
            color: #5e7290;
            width: 540px;
          }
        }
      }
    }
    #structure {
      width: 1200px;
      height: 794px;
      @include bg-image("../../assets/solutions/RenderCloud/structure");
    }
    .advantage-list {
      display: flex;
      justify-content: center;
      margin-bottom: 97px;
      flex-wrap: wrap;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
  }
}
</style>