<template>
  <div class="sub-content" :style="{ backgroundColor: bgcolor }">
    <div
      class="title"
      :style="{
        marginBottom: subtitle
          ? minititle
            ? '17px'
            : '39px'
          : minititle
          ? '31px'
          : '67px',
        color,
      }"
    >
      {{ title }}
    </div>
    <div class="minititle" v-if="minititle">{{ minititle }}</div>
    <div
      class="subtitle"
      :style="{ textAlign: this.textAlignText }"
      v-if="subtitle"
    >
      {{ subtitle }}
    </div>
    <div
      class="subtitle"
      :style="{ textAlign: this.textAlignText }"
      v-if="subtitle"
    >
      {{ subtitle2 }}
    </div>
    <div class="content" data-aos="fade-up" data-aos-duration="500">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "sub-content",
  props: {
    title: {
      type: String,
    },
    minititle: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    subtitle2: {
      type: String,
    },
    bgcolor: {
      type: String,
    },
    color: {
      type: String,
    },
    textAlign: {
      type: String,
    },
  },
  computed: {
    textAlignText() {
      return this.textAlign ?? "center";
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.sub-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin-top: 96px;
    color: #15224a;
    font-size: 40px;
    @media screen and (max-width: 812px) {
      margin-top: 43.5px;
      font-size: 20px;
    }
  }
  .minititle {
    color: #182940;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 42px;
    width: 927px;
    text-align: center;
    line-height: 22px;
  }
  .subtitle {
    text-align: center;
    margin-bottom: 32px;
    line-height: 22px;
    font-size: 14px;
    color: #5e7290;
    font-weight: 400;
    width: 888px;
    @media screen and (max-width: 812px) {
      width: 324.7px;
      font-size: 10px;
      transform: scale(0.83);
      margin-top: -35px;
      margin-bottom: 18px;
    }
  }
  .content {
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }
}
</style>