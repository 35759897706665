import axios from "axios";
import { __STRAPI__PORT__, __STRAPI__HOSTNAME__ } from "./constants";

const axiosInstance = axios.create({
  baseURL: `${window.location.protocol}//${__STRAPI__HOSTNAME__}${__STRAPI__PORT__}`, // 设置基础URL
  timeout: 5000, // 设置请求超时时间
});

// 添加请求拦截器
axiosInstance.interceptors.request.use(
  function(config) {
    // 在发送请求之前可以进行一些全局的请求处理
    // 例如在请求头中添加认证信息、设置请求数据格式等
    return config;
  },
  function(error) {
    // 如果请求出错，可以在这里进行错误处理
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axiosInstance.interceptors.response.use(
  function(response) {
    // 对响应数据进行处理
    return response.data;
  },
  function(error) {
    // 如果响应出错，可以在这里进行错误处理
    return Promise.reject(error);
  }
);

// 导出Axios实例
export default axiosInstance;
