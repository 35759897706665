<template>
  <div class="success">
    <div class="image" :id="image"></div>
  </div>
</template>

<script>
export default {
  name: "success",
  props: {
    image: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.success {
  width: 280px;
  background-color: white;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px 0 #e6edff;
  margin-bottom: 30px;
  border-radius: 10px;
  .image {
    width: 200px;
    height: 60px;
  }
  #lg {
    @include bg-image("../../../assets/solutions/CtsGmsNts/lg");
  }
  #konka {
    @include bg-image("../../../assets/solutions/CtsGmsNts/konka");
  }
  #hisence {
    @include bg-image("../../../assets/solutions/CtsGmsNts/hisence");
  }
  #skyworth {
    @include bg-image("../../../assets/solutions/CtsGmsNts/skyworth");
  }
  #mediatek {
    @include bg-image("../../../assets/solutions/CtsGmsNts/mediatek");
  }
  #tcl {
    @include bg-image("../../../assets/solutions/CtsGmsNts/tcl");
  }
  #cvte {
    @include bg-image("../../../assets/solutions/CtsGmsNts/cvte");
  }
  #boe {
    @include bg-image("../../../assets/solutions/CtsGmsNts/boe");
  }
}
.success:hover {
  box-shadow: 0 0 20px 0 #d8dff0;
}
</style>