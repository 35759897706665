<template>
  <div class="index">
    <top-bar active="index"></top-bar>
    <div class="index-banner">
      <div class="title">最贴近企业用户的云服务解决方案商</div>
      <div class="sub-title">高效、灵活、经济、安全</div>
      <div class="image"></div>
    </div>
    <div class="feature-tag-list-wrap">
      <div class="feature-tag-list">
        <index-feature-tag image="service" title="专业服务">
          强大的技术服务团队<br />
          团队经验丰富<br />
          7*24小时快速响应
        </index-feature-tag>
        <index-feature-tag image="source" title="强大资源">
          整合优质资源<br />
          最大限度提供支持<br />
          深度挖掘痛点
        </index-feature-tag>
        <index-feature-tag image="cooperation" title="灵活合作">
          无缝对接客户流程<br />
          以客户为导向<br />
          充分满足客户需求
        </index-feature-tag>
      </div>
    </div>
    <div class="product-intro-list">
      <product-intro image="network" href="/products/network">
        <template v-slot:title>
          <span>SD-WAN</span>
          <span>软件定义广域网</span>
        </template>
        <template v-slot:content>
          · 面向云的企业分支网络自动化解决方案<br />
          · 利用SDN/NFV和网络智能感知分析技术重构广域网络与服务<br />
          · 实现企业分支网络的虚拟私有专网、安全策略以及QoS的自动化<br />
          · 帮助用户降低IT开支，提升业务访问体验
        </template>
      </product-intro>
      <product-intro image="desktop" href="/products/desktop">
        <template v-slot:title>
          <span>虚拟桌面</span>
          <span style="margin-left: 5px">Desktop virtualization</span>
        </template>
        <template v-slot:content>
          · 采⽤虚拟化技术将个⼈桌⾯集中部署在数据中⼼，实现桌⾯统⼀管控<br />
          · ⽤⼾终端不再需要强⼤计算能⼒，即可实现按需使⽤桌⾯资源<br />
          · 终端与服务器之间依靠图像传输，⽆任何数据，确保信息通道安全
        </template>
      </product-intro>
      <product-intro image="server" href="/products/server">
        <template v-slot:title>
          <span>裸机云</span>
          <span>GPU云服务器</span>
        </template>
        <template v-slot:content>
          · 快速、规模交付专属GPU物理云服务器<br />
          · 提供可靠、稳定、安全、可控的硬件环境<br />
          · 高性能低成本、灵活弹性拓展<br />
          · 可按需自定义配置服务器，大幅提升图形处理和高性能计算能力
        </template>
      </product-intro>
      <product-intro image="system" href="/products/fastsync">
        <template v-slot:title>
          <span>飞安达传输</span>
          <span>大文件传输系统</span>
        </template>
        <template v-slot:content>
          · 满足企业内部或与外部合作伙伴数据传输需求<br />
          · 提供高效可控的大文件加速传输，超远距离、跨国网络数据传输<br />
          · 文件资产安全外发，文件管理与组织权限管理，支持本地部署和云服务<br />
          · 为企业提供安全、稳定、高效、便捷的大文件交互技术支持与服务
        </template>
      </product-intro>
    </div>
    <div class="partner-wrap">
      <div class="title">合作伙伴</div>
      <div class="partner-list">
        <!-- <partner image="rayvision"></partner> -->
        <partner image="china-mobile"></partner>
        <!-- <partner image="raysync"></partner> -->
        <partner image="szmtc"></partner>
      </div>
    </div>
    <contact-us></contact-us>
    <bottom-info></bottom-info>
  </div>
</template>

<script>
import TopBar from "./components/TopBar.vue";
import IndexFeatureTag from "./components/IndexFeatureTag.vue";
import ProductIntro from "./components/ProductIntro.vue";
import Partner from "./components/Partner.vue";
import ContactUs from "./components/ContactUs.vue";
import BottomInfo from "./components/BottomInfo.vue";
export default {
  name: "index",
  components: {
    TopBar,
    IndexFeatureTag,
    ProductIntro,
    Partner,
    ContactUs,
    BottomInfo,
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
@keyframes jump {
  0% {
    margin-top: 52px;
  }
  50% {
    margin-top: 0px;
  }
  100% {
    margin-top: 52px;
  }
}
.index {
  .index-banner {
    position: relative;
    z-index: 1;
    height: 1038.2px;
    background-color: #07235f;
    clip-path: ellipse(80% 81% at 37% 19%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    @media screen and (max-width: 812px) {
      clip-path: inherit;
      padding-bottom: 64px;
      height: 100%;
    }
    .title {
      margin-top: 115px;
      font-weight: bold;
      font-size: 48px;
      @media screen and (max-width: 812px) {
        font-size: 20px;
        margin-top: 59.5px;
      }
    }
    .sub-title {
      margin-top: 30px;
      font-weight: 400;
      font-size: 30px;
      @media screen and (max-width: 812px) {
        font-size: 15px;
        margin-top: 15.5px;
      }
    }
    .image {
      animation: jump 4s infinite;
      @include bg-image("../assets/Index/banner");
      width: 1200px;
      height: 566px;
      margin-top: 26px;
      @media screen and (max-width: 1200px) {
        max-width: 90%;
        height: 0;
        padding-bottom: 47.2%;
      }
      @media screen and (max-width: 812px) {
        animation-name: none;
      }
    }
  }
  .feature-tag-list-wrap {
    display: flex;
    justify-content: center;
    .feature-tag-list {
      position: relative;
      z-index: 2;
      margin-top: -114px;
      display: flex;
      width: 1200px;
      justify-content: space-between;
      flex-wrap: wrap;
      @media screen and (max-width: 1200px) {
        max-width: calc(100% - 40px);
      }
      @media screen and (max-width: 812px) {
        flex-direction: column;
        align-content: center;
        margin-top: 36.5px;
      }
    }
  }
  .product-intro-list {
    margin-top: 39px;
  }
  .partner-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-top: 100px;
      font-size: 40px;
      color: #15224a;
      font-weight: 400;
      @media screen and (max-width: 812px) {
        font-size: 20px;
        margin-top: 35px;
      }
    }
    .partner-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 76px;
      margin-bottom: 127px;
      /* width: 1200px; */
      width: 600px;
      @media screen and (max-width: 1200px) {
        margin-top: 29.5px;
        margin-bottom: 41.5px;
        max-width: calc(100% - 40px);
        > div {
          margin-bottom: 10.5px;
        }
      }
      @media screen and (max-width: 812px) {
        justify-content: center;
        width: 293.5px;
        > div:nth-of-type(2n + 1) {
          margin-right: 13.5px;
        }
      }
    }
  }
}
</style>