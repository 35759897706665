<template>
  <div class="game-advantage">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
    <div class="content" v-if="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "game-advantage",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.game-advantage {
  width: 280px;
  height: 280px;
  background-color: white;
  box-shadow: 0 0 30px 0 #eef3ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image {
    margin-bottom: 39px;
  }
  #computing {
    width: 61px;
    height: 60px;
    @include bg-image("../../../assets/solutions/Game/computing");
  }
  #professional {
    width: 48px;
    height: 60px;
    @include bg-image("../../../assets/solutions/Game/professional");
  }
  #good {
    width: 66px;
    height: 58px;
    margin-bottom: 40px;
    @include bg-image("../../../assets/solutions/Game/good");
  }
  #cross {
    width: 72px;
    height: 60px;
    @include bg-image("../../../assets/solutions/Game/cross");
  }
  .title {
    color: #15224a;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 26px;
  }
  .content {
    color: #5e7290;
    font-weight: 400;
    font-size: 14px;
    width: 228px;
    line-height: 24px;
  }
}
.game-advantage:hover {
  box-shadow: 0 0 30px 0 #e0e5f0;
}
</style>