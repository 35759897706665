<template>
  <div class="partner" data-aos="fade-up" data-aos-duration="500">
    <div class="image" :id="image"></div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    image: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.partner {
  border-radius: 10px;
  background-color: white;
  width: 280px;
  box-shadow: 0 0 30px 0 #e6edff;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 812px) {
    width: 140px;
    height: 60px;
    box-shadow: 0 0 15px 0 #e6edff;
    border-radius: 5px;
  }
  .image {
  }
  #rayvision {
    width: 208px;
    height: 22px;
    @include bg-image("../../assets/Index/rayvision");
    @media screen and (max-width: 812px) {
      width: 104px;
      height: 11px;
    }
  }
  #china-mobile {
    width: 142px;
    height: 45px;
    @include bg-image("../../assets/Index/china-mobile");
    @media screen and (max-width: 812px) {
      width: 71px;
      height: 22.5px;
    }
  }
  #raysync {
    width: 139px;
    height: 44px;
    @include bg-image("../../assets/Index/raysync");
    @media screen and (max-width: 812px) {
      width: 69.5px;
      height: 22px;
    }
  }
  #szmtc {
    width: 178px;
    height: 44px;
    @include bg-image("../../assets/Index/szmtc");
    @media screen and (max-width: 812px) {
      width: 89px;
      height: 22px;
    }
  }
}
.partner:hover {
  box-shadow: 0 0 30px 0 #d1d8eb;
  @media screen and (max-width: 812px) {
    box-shadow: 0 0 15px 0 #d1d8eb;
  }
}
</style>