<template>
  <div class="server">
    <top-bar active="products"></top-bar>
    <banner
      image="server"
      title="GPU云服务器"
      content="GPU云服务器，为企业提供高安全性、高性能、可灵活拓展的GPU专属物理服务器，结合骨干网资源，快速将您的应用部署上云，大幅提升图形处理和高性能计算能力。"
    ></banner>
    <div class="sub-content-list">
      <sub-content title="GPU云架构">
        <div class="structure-wrap">
          <div id="structure-image"></div>
          <div class="text">
            <div class="tag">裸机云解读</div>
            <div class="content">
              借助强大的IT基础设施资源，可快速全球部署裸机云资源
            </div>
            <div class="feature-list">
              <div class="feature">安全性高</div>
              <div class="feature">高可用性</div>
              <div class="feature">灵活可控</div>
              <div class="feature">即时开通</div>
              <div class="feature">灵活可控</div>
              <div class="feature">高性能</div>
            </div>
            <div class="tag">支持的连接场景</div>
            <div class="point">
              · 香港及亚太区域部署<br />
              · 中国分布式部署<br />
              · 东南亚、欧美、非洲及中东区域部署<br />
              · 与全球IDC、公有云、私有云建立内网
            </div>
          </div>
        </div>
      </sub-content>
      <sub-content
        title="混合云部署"
        subtitle="裸机云保障性能，云主机应对峰值；专属网络直连全球公有云与裸机云POPs，快速部署混合云架构，轻松应对流量洪峰"
      >
        <div class="deployment-wrap">
          <div class="cloud-image" id="public-image"></div>
          <div class="cloud-image" id="bare-image"></div>
        </div>
      </sub-content>
      <sub-content title="产品优势" bgcolor="#F8FAFF">
        <div class="server-advantage-list">
          <server-advantage
            image="safety"
            title="高安全性"
            content="拥有独立物理机，网络单独隔离"
          ></server-advantage>
          <server-advantage
            image="immediately"
            title="即时开通"
            content="下单4小时内上线"
          ></server-advantage>
          <server-advantage
            image="flexible"
            title="灵活可控"
            content="轻松增加性能、带宽、服务器"
          ></server-advantage>
          <server-advantage
            image="performance"
            title="高性能"
            content="支持数据密集型的高负载业务"
          ></server-advantage>
        </div>
      </sub-content>
      <sub-content title="专业服务">
        <div class="profession-wrap">
          <div id="profession-image"></div>
          <div class="profession-list">
            <profession
              image="personal"
              title="1 Vs 1"
              content="经验丰富的技术支持专家提供一对一服务，为您的业务全程护航。"
            ></profession>
            <profession
              image="allday"
              title="7*24 h"
              content="运维团队7* 24小时守护，快速处理突发事件。"
            ></profession>
            <profession
              image="minutes"
              title="5 min"
              content="工单快速响应，为您打造便捷的客户服务体系。"
            ></profession>
          </div>
        </div>
      </sub-content>
      <sub-content title="应用场景">
        <div class="scene-list">
          <scene image="design" title="视觉设计"></scene>
          <scene image="game" title="云游戏"></scene>
          <scene image="movie" title="影视制作"></scene>
          <scene image="desktop" title="云桌面"></scene>
          <scene image="ai" title="AI计算"></scene>
        </div>
      </sub-content>
    </div>
    <contact-us></contact-us>
    <bottom-info></bottom-info>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import Banner from "../components/Banner.vue";
import SubContent from "../components/SubContent.vue";
import ServerAdvantage from "./components/ServerAdvantage.vue";
import Profession from "./components/Profession.vue";
import Scene from "./components/Scene.vue";
import ContactUs from "../components/ContactUs.vue";
import BottomInfo from "../components/BottomInfo.vue";
export default {
  name: "server",
  components: {
    TopBar,
    Banner,
    SubContent,
    ServerAdvantage,
    Profession,
    Scene,
    ContactUs,
    BottomInfo,
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.server {
  .sub-content-list {
    .structure-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 17px;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
      }
      #structure-image {
        width: 679px;
        height: 430px;
        @include bg-image("../../assets/products/Server/structure");
        margin-right: 99px;
        @media screen and (max-width: 1200px) {
          margin-right: 0;
          width: 90.5%;
          height: 0;
          margin-top: -35.5px;
          padding-bottom: 57.3%;
          margin-bottom: 8.5px;
        }
      }
      .text {
        .tag {
          display: inline-block;
          border-radius: 4px;
          height: 34px;
          background-color: #f2f5fe;
          line-height: 34px;
          padding: 0 15px;
          font-size: 16px;
          font-weight: 400;
          color: #0050fc;
          @media screen and (max-width: 1200px) {
            font-size: 12px;
            border-radius: 2px;
            height: 26px;
            line-height: 26px;
            padding: 0 11px;
          }
        }
        .content {
          margin-top: 19px;
          color: #15224a;
          font-weight: 400;
          @media screen and (max-width: 1200px) {
            margin-top: 14px;
            font-size: 12px;
          }
        }
        .feature-list {
          margin-top: 29px;
          display: flex;
          width: 257px;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 18px;
          @media screen and (max-width: 1200px) {
            width: 220px;
            margin-top: 22px;
            margin-bottom: 8.5px;
          }
          .feature {
            width: 90px;
            color: #5e7290;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 19px;
            @media screen and (max-width: 1200px) {
              font-size: 10px;
              transform: scale(0.83);
              transform-origin: 0 0;
              margin-bottom: 16.8px;
            }
          }
          .feature::before {
            content: "";
            display: inline-block;
            width: 19px;
            height: 13px;
            @include bg-image("../../assets/products/Server/click");
            margin-right: 10px;
            @media screen and (max-width: 1200px) {
              width: 13px;
              height: 9px;
            }
          }
        }
        .point {
          margin-top: 17px;
          color: #15224a;
          font-size: 14px;
          line-height: 30px;
          font-weight: 400;
          @media screen and (max-width: 1200px) {
            margin-top: 13px;
            font-size: 10px;
            transform: scale(0.83);
            transform-origin: 0 0;
            line-height: 25px;
          }
        }
      }
    }
    .deployment-wrap {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
        flex-direction: column;
        align-items: center;
        margin-bottom: 21px;
      }
      .cloud-image {
        width: 580px;
        height: 400px;
        margin-bottom: 144px;
        @media screen and (max-width: 1200px) {
          margin-bottom: 15px;
          width: 85%;
          height: 0;
          padding-bottom: 58.6%;
        }
      }
      #public-image {
        @include bg-image("../../assets/products/Server/public");
      }
      #bare-image {
        @include bg-image("../../assets/products/Server/bare");
      }
    }
    .server-advantage-list {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 117px;
      @media screen and (max-width: 1200px) {
        flex-wrap: wrap;
        justify-content: space-evenly;
        max-width: calc(100% - 24px);
        margin: -30px auto 27.5px;
      }
    }
    .profession-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 25px;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
      }
      #profession-image {
        margin-right: 54px;
        width: 589px;
        height: 365px;
        @include bg-image("../../assets/products/Server/profession");
        @media screen and (max-width: 1200px) {
          margin-top: -30px;
          margin-right: 0;
          height: 0;
          width: 78.5%;
          padding-bottom: 48.6%;
        }
      }
      .profession-list {
        @media screen and (max-width: 1200px) {
          margin-top: 36px;
          width: calc(100% - 45px);
        }
      }
    }
    .scene-list {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 102px;
      @media screen and (max-width: 1200px) {
        justify-content: space-evenly;
        max-width: calc(100% - 14px);
        margin: -30px auto 0;
      }
    }
    .scene-list:after {
      content: "";
      width: 388px;
    }
  }
}
</style>