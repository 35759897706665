<template>
  <div class="education-advantage">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
    <div class="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "education-advantage",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.education-advantage {
  width: 380px;
  height: 240px;
  background-color: white;
  box-shadow: 0 0 40px 0 #eef3ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  .image {
    margin-top: 38px;
    margin-bottom: 26px;
  }
  #flex {
    width: 59px;
    height: 60px;
    margin: 39.25px 0 27.25  px 0;
    @include bg-image("../../../assets/solutions/Education/flex");
  }
  #central {
    width: 60px;
    height: 55px;
    margin: 41.75px 0 29.75px 0;
    @include bg-image("../../../assets/solutions/Education/central");
  }
  #green {
    width: 61px;
    height: 61px;
    margin: 38.75px 0 26.75px 0;
    @include bg-image("../../../assets/solutions/Education/green");
  }
  #safe {
    width: 76px;
    height: 59px;
    margin: 39.75px 0 27.75px 0;
    @include bg-image("../../../assets/solutions/Education/safe");
  }
  #needed {
    width: 56px;
    height: 60px;
    margin: 39.25px 0 27.25px 0;
    @include bg-image("../../../assets/solutions/Education/needed");
  }
  #education {
    width: 64px;
    height: 62.5px;
    @include bg-image("../../../assets/solutions/Education/education");
  }
  .title {
    color: #15224A;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 18px;
  }
  .content {
    text-align: center;
    color: #5E7290;
    font-weight: 400;
    font-size: 14px;
    width: 340px;
    line-height: 24px;
  }
}
.education-advantage:hover {
  box-shadow: 0 0 40px 0 #dee3ee;
}
</style>