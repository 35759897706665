import { request, gql } from "graphql-request";
import { __STRAPI__HOSTNAME__, __STRAPI__PORT__ } from "./constants";
import { GetPrevAndNextNewsTitle } from "./gql";

const endpoint = `${location.protocol}//${__STRAPI__HOSTNAME__}${__STRAPI__PORT__}/graphql`; // 设置基础URL

export const getPrevAndNextNewsTitle = async (variables) => {
  const q = gql`
    ${GetPrevAndNextNewsTitle}
  `;
  const response = await request(endpoint, q, variables);
  return response;
};
