import { render, staticRenderFns } from "./GameAdvantage.vue?vue&type=template&id=6033f2cc&scoped=true&"
import script from "./GameAdvantage.vue?vue&type=script&lang=js&"
export * from "./GameAdvantage.vue?vue&type=script&lang=js&"
import style0 from "./GameAdvantage.vue?vue&type=style&index=0&id=6033f2cc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6033f2cc",
  null
  
)

export default component.exports