<template>
  <div class="education">
    <top-bar active="solutions" theme="white"></top-bar>
    <solutions-banner
      image="education"
      content="针对教育培训行业定制的解决方案，通过资源虚拟化整合为图形工作站提供专业的高性能图形处理能力，节约投资，降低成本，便于升级和运维管理，已被高校及培训机构所采用。"
    >
      教育培训行业解决方案
    </solutions-banner>
    <div class="sub-content-list">
      <sub-content title="行业现状" bgcolor="#F8FAFF">
        <div class="present-list">
          <present
            image="hard"
            title="机房环境准备困难"
            content="需要机器逐台安装及部署软件"
          >
          </present>
          <present
            image="expensive"
            title="桌面系统运维成本高"
            content="操作系统、补丁升级、软件升级在每台PC上单独操作，耗时耗力"
          >
          </present>
          <present
            image="unsafe"
            title="安全隐患"
            content="教室人员流动频繁，使用PC外设容易中病毒，造成安全隐患"
          >
          </present>
          <present
            image="consumption"
            title="功耗大"
            content="传统PC功耗大、噪声响、辐射大，存在机器过烫等问题"
          >
          </present>
        </div>
      </sub-content>
      <sub-content
        title="解决方案"
        subtitle="针对教育培训行业提供低成本高性能的桌面方案，按照不同教学场景需求定制化机器配置，支持多媒体播放，多种GPU显卡可选，适用于普教、编程、3D设计等不同类型的教学场景。"
      >
        <div id="solution-image"></div>
      </sub-content>
      <sub-content title="方案优势" bgcolor="#F8FAFF">
        <div class="education-advantage-list">
          <education-advantage
            image="flex"
            title="灵活访问"
            content="任意时间、任意地点，可选多种终端设备访问，易用性高"
          ></education-advantage>
          <education-advantage
            image="central"
            title="集中管理统一配置"
            content="根据教学环境定制系统及镜像，统一分发桌面，集中管理统一配置，方便运维"
          ></education-advantage>
          <education-advantage
            image="green"
            title="降低功耗、节能减排"
            content="传统PC 150w-200w、图形工作站450w-950w，使用瘦终端功率15W，极大的降低了功耗，耗电量大幅下降"
          ></education-advantage>
          <education-advantage
            image="safe"
            title="数据安全"
            content="数据运行与存储在云端，终端仅作为显示，可设置权限数据无法拷贝，安全有保障"
          ></education-advantage>
          <education-advantage
            image="needed"
            title="资源按需分配"
            content="针对不同的教学场景要求，提供不同配置的机器"
          ></education-advantage>
          <education-advantage
            image="education"
            title="流畅的教学体验"
            content="虚拟桌面多台并发启动、程序运行、关机速度流畅，兼容各类教学软件"
          ></education-advantage>
        </div>
      </sub-content>
    </div>
    <contact-us></contact-us>
    <bottom-info></bottom-info>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import SolutionsBanner from "../components/SolutionsBanner.vue";
import SubContent from "../components/SubContent.vue";
import Present from "./components/Present.vue";
import EducationAdvantage from "./components/EducationAdvantage.vue";
import ContactUs from "../components/ContactUs.vue";
import BottomInfo from "../components/BottomInfo.vue";
export default {
  name: "education",
  components: {
    TopBar,
    SolutionsBanner,
    SubContent,
    Present,
    EducationAdvantage,
    ContactUs,
    BottomInfo,
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.education {
  .sub-content-list {
    .present-list {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 99px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
    #solution-image {
      width: 936px;
      height: 416px;
      margin-bottom: 99px;
      @include bg-image("../../assets/solutions/Education/solution");
    }
    .education-advantage-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 1200px;
      margin-bottom: 73px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
  }
}
</style>