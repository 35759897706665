<template>
  <div class="news-list">
    <div
      v-for="(item, index) in newsList"
      class="news-item"
      :key="index"
      @click="handleToDetail(item.id)"
    >
      <img class="news-pic" alt="" :src="item.coverUrl" />
      <div class="news-item-right">
        <div>
          <div class="news-title">
            {{ item.title }}
          </div>
          <div class="news-desc">
            {{ item.content }}
          </div>
        </div>
        <div class="news-time">
          <Clock class="news-clock" />
          <span clsss="news-time-text">{{ item.publishedAt }}</span>
        </div>
      </div>
    </div>
    <pagination
      :current="current"
      :total="total"
      :size="size"
      @current="(c) => handleCurrent(c)"
    />
  </div>
</template>

<script>
import Clock from "../../../assets/News/time.svg";
import Pagination from "../../components/Pagination.vue";

import { getNewsList } from "../../../services/api";
import ContentService from "../../../services/content-service";

export default {
  name: "news-list",
  components: {
    Clock,
    Pagination,
  },
  computed: {
    pages() {
      return Math.ceil(this.total / this.size);
    },
  },
  props: {
    searchCurrent: Number,
    searchText: String,
  },
  data() {
    return {
      newsList: [],
      size: 8,
      total: 0,
      current: 1,
      text: "",
    };
  },
  mounted() {
    this.getNewsList();
  },
  watch: {
    searchText(v) {
      this.text = v;
    },
  },
  methods: {
    resetCurrent(text) {
      text && (this.text = text);
      this.handleCurrent(1);
    },
    handleCurrent(c) {
      this.current = c;
      this.getNewsList();
    },
    getNewsList() {
      getNewsList({
        pageSize: this.size,
        page: this.current,
        keywords: this.text,
      }).then((res) => {
        const { data, meta } = res;
        this.newsList = data.map((item) => {
          return { ...item.attributes, id: item.id };
        });
        this.newsList.forEach((item) => {
          item.publishedAt = new Date(item.publishedAt)
            .toLocaleDateString()
            .split("/")
            .join("-");
          item.coverUrl = ContentService.getCoverImg(item.content);
          item.content = ContentService.getAbstract(item.content);
        });
        this.total = meta.pagination.total;
      });
    },
    handleToDetail(id) {
      this.text = "";
      this.$emit("setText", this.text);
      this.$router.push(`/news/detail/${id}`);
    },
  },
};
</script>

<style scoped lang="scss">
.news-list {
  display: flex;
  flex-direction: column;
  width: 780px;
  .news-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 15px 0px rgba(102, 102, 102, 0.3);
    }
    .news-pic {
      width: 240px;
      height: 160px;
    }
    .news-item-right {
      width: calc(100% - 240px - 16px);
      height: calc(100% - 24px);
      margin-left: 16px;
      padding: 12px 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      .news-title {
        font-size: 18px;
        font-weight: 400;
        color: #15224a;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .news-desc {
        margin-top: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #5e7290;
        line-height: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .news-time {
        display: flex;
        align-items: center;
        .news-clock {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }
        > span {
          font-size: 12px;
          font-weight: 400;
          color: #5e7290;
        }
      }
    }
  }
}
</style>
