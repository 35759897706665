import qs from "qs";
import axiosInstance from "./axios";

export const getNewsList = ({ pageSize, page, keywords }) => {
  let param = {
    populate: "*",
    pagination: {
      page,
      pageSize,
    },
    sort: ["publishedAt:desc"],
  };
  if (keywords) {
    param = {
      ...param,
      filters: {
        keywords: {
          text: { $eq: keywords },
        },
      },
    };
  }
  const query = qs.stringify(param, {
    encodeValuesOnly: true, // prettify URL
  });

  return axiosInstance.get(`/api/newslist?${query}`);
};
export const getNewsDetail = ({ id }) => {
  return axiosInstance.get(`/api/newslist/${id}`);
};

export const getKeywordList = () =>
  axiosInstance.get("/api/keywords?pagination[pageSize]=100");
