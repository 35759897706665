<template>
  <div class="raysync-feature" :class="{ 'active-line': nowActive === active }" @click="$emit('nowactive', active)">
    <div class="image" :id="image"></div>
    <div class="title" :class="{ active: nowActive === active }">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "raysync-feature",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    active: {
      type: Number,
    },
    nowActive: {
      type: Number,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.raysync-feature {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  .image {
    margin-bottom: 16px;
  }
  #performance {
    width: 39px;
    height: 44px;
    @include bg-image("../../../assets/products/Raysync/performance");
  }
  #compatibility {
    width: 40px;
    height: 44px;
    @include bg-image("../../../assets/products/Raysync/compatibility");
  }
  #multiple-way {
    width: 44px;
    height: 38px;
    @include bg-image("../../../assets/products/Raysync/multipleWay");
  }
  #reliable {
    width: 40px;
    height: 44px;
    @include bg-image("../../../assets/products/Raysync/reliable");
  }
  #safety {
    width: 40px;
    height: 44px;
    @include bg-image("../../../assets/products/Raysync/safety");
  }
  #central {
    width: 44px;
    height: 44px;
    @include bg-image("../../../assets/products/Raysync/central");
  }
  .title {
    margin-bottom: 24px;
    color: #15224a;
    font-size: 16px;
    font-weight: 400;
  }
  .active {
    color: #145cf6;
  }
}
.active-line::after {
  position: absolute;
  bottom: 0;
  left: -2px;
  content: "";
  height: 4px;
  width: calc(100% + 4px);
  background-color: #145CF6;
}
</style>