<template>
  <div class="about">
    <top-bar active="about" theme="white"></top-bar>
    <div class="about-banner" id="about-image">
      <div class="title-wrap" data-aos="fade-up" data-aos-duration="500">
        <div class="en-title">About Us</div>
        <div class="title">关于我们</div>
      </div>
    </div>
    <div class="text" data-aos="fade-up" data-aos-duration="500">
      <div class="text-wrap">
        <div class="title">深圳市国信网服技术有限公司</div>
        <div class="content">
          深圳市国信网服技术有限公司(本文中简称“国信网服”)，面向政企，致力于成为最贴近用户的专业IT服务解决方案商，让客户高效、灵活、
          经济、安全的使用IT设施、服务和应用。通过强大的技术能力、专业的运营维护支持、整合优质的资源，充分满足用户的需求，为政企客户提供网络优化、多点组网、大文件传输、桌面虚拟化、实时渲染、虚拟仿真等产品和服务，包括“国际网络访问优化服务”、“
          SD-WAN智能组网”、“飞安达大文件传输加速”、“虚拟桌面”、“三维虚拟仿真应用”等。
        </div>
      </div>
    </div>
    <bottom-info></bottom-info>
  </div>
</template>

<script>
import TopBar from "./components/TopBar.vue";
import BottomInfo from "./components/BottomInfo.vue";
export default {
  name: "index",
  components: {
    TopBar,
    BottomInfo,
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.about {
  .about-banner {
    display: flex;
    justify-content: center;
    .title-wrap {
      width: 1200px;
      display: flex;
      align-items: space-between;
      justify-content: center;
      flex-direction: column;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
      .en-title {
        color: #145cf6;
        margin-bottom: 22px;
        font-weight: bold;
        font-size: 36px;
      }
      .title {
        color: white;
        font-weight: bold;
        font-size: 60px;
        font-weight: 400;
      }
    }
  }
  #about-image {
    height: 520px;
    @include bg-image-raw("../assets/About/banner");
  }
  .text {
    padding-top: 73px;
    margin-bottom: 110px;
    display: flex;
    justify-content: center;
    .text-wrap {
      width: 1200px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
      .title {
        color: #182940;
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 40px;
      }
      .content {
        color: #5e7290;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}
</style>