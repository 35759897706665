<template>
  <div class="present">
    <div class="image" :id="image"></div>
    <div class="text">
      <div class="title">{{ title }}</div>
      <div class="content" v-if="content">{{ content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "present",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.present {
  width: 590px;
  background-color: white;
  height: 130px;
  display: flex;
  box-shadow: 0 0 30px 0 #eef3ff;
  margin-bottom: 20px;
  align-items: center;
  .image {
    margin-left: 30px;
  }
  #hard {
    width: 41px;
    margin: 0 5px 0 35px;
    height: 45px;
    @include bg-image("../../../assets/solutions/Education/hard");
  }
  #expensive {
    width: 51px;
    height: 44px;
    @include bg-image("../../../assets/solutions/Education/expensive");
  }
  #unsafe {
    width: 48px;
    margin: 0 1.5px 0 31.5px;
    height: 44px;
    @include bg-image("../../../assets/solutions/Education/unsafe");
  }
  #consumption {
    width: 40px;
    margin: 0 5.5px 0 35.5px;
    height: 46px;
    @include bg-image("../../../assets/solutions/Education/consumption");
  }
  .text {
    margin-left: 30px;
    .title {
      color: #15224A;
      font-weight: 400;
      font-size: 20px;
      margin-bottom: 17px;
    }
    .content {
      color: #5e7290;
      font-weight: 400;
      font-size: 14px;
      width: 448px;
      line-height: 22px;
    }
  }
}
.present:hover {
  box-shadow: 0 0 30px 0 #dfe4f0;
}
</style>