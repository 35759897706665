<template>
  <div class="network-advantage">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
    <div class="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "network-advantage",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.network-advantage {
  background-color: white;
  width: 380px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 40px 0 #eef3ff;
  margin-bottom: 30px;
  @media screen and (max-width: 812px) {
    width: calc(50% - 6px);
    height: 130px;
    margin-bottom: 10px;
  }
  .image {
  }
  #solution {
    width: 60px;
    height: 60px;
    @include bg-image("../../../assets/products/Network/solution");
    @media screen and (max-width: 812px) {
      width: 30px;
      height: 30px;
    }
  }
  #quick {
    width: 57px;
    height: 61px;
    @include bg-image("../../../assets/products/Network/quick");
    @media screen and (max-width: 812px) {
      width: 28.5px;
      height: 30.5px;
    }
  }
  #cheap {
    width: 60px;
    height: 60px;
    @include bg-image("../../../assets/products/Network/cheap");
    @media screen and (max-width: 812px) {
      width: 30px;
      height: 30px;
    }
  }
  #performance {
    width: 55px;
    height: 61px;
    @include bg-image("../../../assets/products/Network/performance");
    @media screen and (max-width: 812px) {
      width: 27.5px;
      height: 30.5px;
    }
  }
  #visibility {
    width: 53px;
    height: 60px;
    @include bg-image("../../../assets/products/Network/visibility");
    @media screen and (max-width: 812px) {
      width: 26.5px;
      height: 30px;
    }
  }
  #mamagement {
    width: 60px;
    height: 57px;
    @include bg-image("../../../assets/products/Network/mamagement");
    @media screen and (max-width: 812px) {
      width: 30px;
      height: 28.5px;
    }
  }
  .title {
    margin-top: 25px;
    color: #15224a;
    font-size: 24px;
    font-weight: 400;
    @media screen and (max-width: 812px) {
      margin-top: 10px;
      font-size: 15px;
    }
  }
  .content {
    margin-top: 19px;
    color: #5e7290;
    font-size: 14px;
    font-weight: 400;
    @media screen and (max-width: 812px) {
      margin-top: 10px;
      font-size: 10px;
      transform: scale(0.83);
      text-align: center;
    }
  }
}
.network-advantage:hover {
  box-shadow: 0 0 40px 0 #e0e4f0;
}
</style>