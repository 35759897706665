<template>
  <div class="news">
    <top-bar active="news" theme="white"></top-bar>
    <div class="news-banner" id="news-image">
      <div class="title-wrap" data-aos="fade-up" data-aos-duration="500">
        <div class="title">新闻动态</div>
      </div>
    </div>
    <div class="news-container" data-aos="fade-up" data-aos-duration="500">
      <router-view
        ref="newsList"
        :search-text="text"
        @setText="(t) => (text = t)"
      ></router-view>
      <div class="news-right-content">
        <div class="news-right-title">
          <div class="blue"></div>
          <div class="news-right-title-text">热搜关键词</div>
        </div>
        <div class="news-search">
          <input placeholder="搜索" v-model="text" />
          <div class="news-search-button" @click="handleSearchKeyword()">
            搜索
          </div>
        </div>
        <div class="news-tag">
          <div
            class="news-tag-item"
            v-for="(item, index) in keywordList"
            :key="index"
          >
            <span class="news-tag-text" @click="handleClickKeyword(item)">{{
              item.text
            }}</span>
            <span v-if="index !== keywordList.length - 1" class="split-line"
              >|</span
            >
          </div>
        </div>
        <div class="news-new">
          <div class="news-right-title">
            <div class="blue"></div>
            <div class="news-right-title-text">最新资讯</div>
          </div>
        </div>
        <div class="news-new-list">
          <div
            class="news-new-list-item"
            v-for="(item, index) in newsNewList"
            :key="index"
            @click="handleToDetail(item.id)"
          >
            <img :src="item.coverUrl" class="news-new-pic" />
            <div class="news-new-right">
              <div class="news-new-title">
                {{ item.title }}
              </div>
              <div class="news-new-time">{{ item.publishedAt }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottom-info></bottom-info>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import BottomInfo from "../components/BottomInfo.vue";

import { getNewsList, getKeywordList } from "../../services/api";
import ContentService from "../../services/content-service";

export default {
  name: "news",
  components: {
    TopBar,
    BottomInfo,
  },
  data() {
    return {
      size: 8,
      text: "",
      keywordList: [],
      newsNewList: [],
    };
  },
  mounted() {
    this.getKeywordList();
    this.getNewsNewList();
    this.$watch("$route", (newRoute) => {
      if (newRoute.path === "/news/list") {
        if (newRoute.query.text) {
          this.text = newRoute.query.text;
        }
        this.$nextTick(() => this.$refs.newsList.resetCurrent(this.text));
      }
    });
  },
  methods: {
    getKeywordList() {
      getKeywordList().then((res) => {
        const { data } = res;
        this.keywordList = data.map((item) => ({
          ...item.attributes,
          id: item.id,
        }));
      });
    },
    getNewsNewList() {
      getNewsList({ pageSize: this.size, page: 1 }).then((res) => {
        const { data } = res;
        this.newsNewList = data.map((item) => {
          return { ...item.attributes, id: item.id };
        });
        this.newsNewList.forEach((item) => {
          item.publishedAt = new Date(item.publishedAt)
            .toLocaleDateString()
            .split("/")
            .join("-");
          item.coverUrl = ContentService.getCoverImg(item.content);
        });
      });
    },
    handleClickKeyword(item) {
      if (this.$route.path !== "/news/list") {
        this.$router.push({ path: "/news/list", query: { text: item.text } });
      } else {
        this.text = item.text;
        this.$nextTick(() => this.$refs.newsList.resetCurrent());
      }
    },
    handleSearchKeyword() {
      if (this.$route.path !== "/news/list") {
        this.$router.push({ path: "/news/list", query: { text: this.text } });
      } else {
        this.$nextTick(() => this.$refs.newsList.resetCurrent());
      }
    },
    handleToDetail(id) {
      this.text = "";
      this.$router.push(`/news/detail/${id}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.news {
  .news-banner {
    display: flex;
    justify-content: center;
    .title-wrap {
      width: 1200px;
      display: flex;
      align-items: space-between;
      justify-content: center;
      flex-direction: column;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
      .title {
        font-size: 48px;
        color: #145cf6;
        line-height: 72px;
      }
    }
  }
  #news-image {
    height: 240px;
    @include bg-image-raw("../../assets/News/banner");
  }
  .news-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    .news-right-content {
      margin-left: 56px;
      .news-right-title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .blue {
          width: 4px;
          height: 24px;
          background: #145cf6;
        }
        .news-right-title-text {
          font-size: 20px;
          font-weight: 400;
          color: #15224a;
          line-height: 20px;
          margin-left: 10px;
        }
      }
      .news-search {
        display: flex;
        input {
          width: 250px;
          height: 38px;
          border: 1px solid #e5e5e5;
          padding: 0 14px;
          font-size: 16px;
          font-weight: 400;
          line-height: 38px;
          &::placeholder {
            font-size: 16px;
            font-weight: 400;
            color: #bfbfbf;
          }
        }
        .news-search-button {
          width: 100px;
          height: 40px;
          background: #145cf6;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          cursor: pointer;
        }
      }
      .news-tag {
        margin-top: 25px;
        display: flex;
        flex-wrap: wrap;
        width: 380px;
        .news-tag-item {
          font-size: 14px;
          font-weight: 400;
          color: #5e7290;
          line-height: 22px;
          .news-tag-text {
            cursor: pointer;
          }
          .split-line {
            margin: 0 8px;
          }
        }
      }
      .news-new {
        margin-top: 41px;
      }
      .news-new-list {
        margin-top: 27px;
        .news-new-list-item {
          margin-bottom: 16px;
          display: flex;
          margin-top: 27px;
          flex-shrink: 0;
          cursor: pointer;
          .news-new-pic {
            width: 120px;
            height: 80px;
          }
          .news-new-right {
            margin-left: 12px;
            .news-new-title {
              font-size: 14px;
              font-weight: 400;
              color: #15224a;
              line-height: 22px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              width: 245px;
            }
            .news-new-time {
              margin-top: 19px;
              font-size: 12px;
              font-weight: 400;
              color: #5e7290;
            }
          }
        }
      }
    }
  }
}
</style>