<template>
  <div class="evaluation">
    <div class="image" :id="image"></div>
    <div class="title">{{ title }}</div>
    <div class="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "evaluation",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.evaluation {
  width: 272px;
  height: 436px;
  box-shadow: 0 0 30px 0 #ebf0ff;
  background-color: white;
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: auto;
    box-shadow: 0 0 15px 0 #ebf0ff;
    margin-bottom: 9.5px;
  }
  .image {
    width: 212px;
    height: 63px;
    margin: 30px 0 0 30px;
    @media screen and (max-width: 1200px) {
      width: 106px;
      height: 31.5px;
      margin: 15px 0 0 18px;
    }
  }
  #golden-seattle {
    @include bg-image("../../../assets/products/Desktop/goldenSeattle");
  }
  #rayclouds {
    @include bg-image("../../../assets/products/Desktop/rayclouds");
  }
  #byte-dance {
    @include bg-image("../../../assets/products/Desktop/byteDance");
  }
  #pxy-technologies {
    @include bg-image("../../../assets/products/Desktop/pxyTechnologies");
  }
  .title {
    margin: 26px 0 0 30px;
    color: #15224a;
    font-size: 20px;
    font-weight: 400;
    @media screen and (max-width: 1200px) {
      font-size: 15px;
      margin: 10px 0 0 18px;
    }
  }
  .content {
    width: 210px;
    margin: 19px 0 0 30px;
    line-height: 23px;
    color: #8a9ab1;
    font-size: 14px;
    font-weight: 400;
    @media screen and (max-width: 1200px) {
      width: calc(100% + 30px);
      margin: 10px 0 18px 18px;
      line-height: 18px;
      font-size: 10px;
      transform: scale(0.83);
      transform-origin: 0 0;
    }
  }
}
.evaluation:hover {
  box-shadow: 0 0 30px 0 #e0e5f3;
}
</style>