var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub-content",style:({ backgroundColor: _vm.bgcolor })},[_c('div',{staticClass:"title",style:({
      marginBottom: _vm.subtitle
        ? _vm.minititle
          ? '17px'
          : '39px'
        : _vm.minititle
        ? '31px'
        : '67px',
      color: _vm.color,
    })},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.minititle)?_c('div',{staticClass:"minititle"},[_vm._v(_vm._s(_vm.minititle))]):_vm._e(),(_vm.subtitle)?_c('div',{staticClass:"subtitle",style:({ textAlign: this.textAlignText })},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e(),(_vm.subtitle)?_c('div',{staticClass:"subtitle",style:({ textAlign: this.textAlignText })},[_vm._v(" "+_vm._s(_vm.subtitle2)+" ")]):_vm._e(),_c('div',{staticClass:"content",attrs:{"data-aos":"fade-up","data-aos-duration":"500"}},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }