<template>
  <div class="raysync">
    <top-bar active="products"></top-bar>
    <banner
      bg="raysync-bg"
      title="飞安达大文件传输系统"
      content="飞安达大文件传输系统，一站式提升全球企业数据流转效率。不受传统文件传输方式（如FTP、HTTP或CIFS）影响，最大化降低大文件、大数据传输时延与丢包率，充分利用网络带宽资源，实现各种业务系统与各类操作系统平台间的数据文件协作，解决大文件传输、大数据孤岛问题。"
    ></banner>
    <div class="sub-content-list">
      <sub-content title="系统特点">
        <div class="raysync-feature-wrap">
          <div class="raysync-feature-list">
            <raysync-feature
              image="performance"
              title="高性能传输"
              :active="0"
              :now-active="nowActive"
              @nowactive="setNowActive"
            ></raysync-feature>
            <raysync-feature
              image="safety"
              title="网银级安全保障"
              :active="1"
              :now-active="nowActive"
              @nowactive="setNowActive"
            ></raysync-feature>
            <raysync-feature
              image="reliable"
              title="数据稳定高效"
              :active="2"
              :now-active="nowActive"
              @nowactive="setNowActive"
            ></raysync-feature>
            <raysync-feature
              image="multiple-way"
              title="多方式传输"
              :active="3"
              :now-active="nowActive"
              @nowactive="setNowActive"
            ></raysync-feature>
            <raysync-feature
              image="central"
              title="全局中央控制"
              :active="4"
              :now-active="nowActive"
              @nowactive="setNowActive"
            ></raysync-feature>
            <raysync-feature
              image="compatibility"
              title="良好适应性和兼容性"
              :active="5"
              :now-active="nowActive"
              @nowactive="setNowActive"
            ></raysync-feature>
          </div>
          <div class="raysync-info">
            <div class="image-wrap">
              <div
                class="image"
                v-show="nowActive === 0"
                id="performance-content-image"
              ></div>
              <div
                class="image"
                v-show="nowActive === 1"
                id="safety-content-image"
              ></div>
              <div
                class="image"
                v-show="nowActive === 2"
                id="reliable-content-image"
              ></div>
              <div
                class="image"
                v-show="nowActive === 3"
                id="multiple-way-content-image"
              ></div>
              <div
                class="image"
                v-show="nowActive === 4"
                id="central-content-image"
              ></div>
              <div
                class="image"
                v-show="nowActive === 5"
                id="compatibility-content-image"
              ></div>
            </div>
            <div class="text" v-show="nowActive === 0">
              飞安达传输为实现稳定的高速文件传输，通过内置超高速传输协议，智能压缩等技术，传输速度提升到FTP的100倍。同时对网络带宽自动智能优化，充分利用现有带宽，有效降低网络延时、丢包等影响，实现超远程、跨境大文件、海量小文件高速传输。无需特别调整即可发挥高效、稳定性能。
            </div>
            <div class="text" v-show="nowActive === 1">
              为确保传输数据安全，防止数据泄露、破解、监听等安全问题，飞安达传输强化内部数据通信安全性管控，采用网银级AES-256加密技术，传输过程中使用SSL加密传输，有效确保数据安全。同时，飞安达传输采用访问权限与OS权限设置，达到了更加严密的访问控制。
            </div>
            <div class="text" v-show="nowActive === 2">
              在传输过程中，为确保数据传输稳定、完整性，飞安达传输通过断点续传、错误重传、多重文件校验、智能同步等机制，确保数据在传输过程中因网络故障、传输异常等情况发生时，数据传输能够获得有效保障，同时缩短输出耗时，减轻工作负担。
            </div>
            <div class="text" v-show="nowActive === 3">
              飞安达传输支持根据不同的传输发起方提供多种模式传输方式。支持点对点、多点互传，数据云传输，一对多数据分发等模式。提供专属用户、用户组空间，支持特定权限管理，构建跨部门、跨公司的专属协助空间及文件传输需求。
            </div>
            <div class="text" v-show="nowActive === 4">
              系统对数据传输日志、用户操作日志、用户管理、用户权限管理提供有效管理模块，通过采取系统管控、文档访问细颗粒度权限、数据防泄漏、共享权限策略，确保数据在传输、存储过程中得到有效管控，保障敏感信息安全，数据传输内容流向可追溯，打造稳固、可控的数据传输安全环境
            </div>
            <div class="text" v-show="nowActive === 5">
              飞安达传输支持UNIX, Linux,
              Windows等常用操作系统，支持阿里云、亚马逊云、华为云等云平台操作系统，提供专门SDK集成产品，能够与企业现有系统快速集成部署，轻松搭建企业内部或企业间的网络环境、异构系统的自动化文件传输网络。
            </div>
          </div>
        </div>
      </sub-content>
      <sub-content title="主要功能" bgcolor="#F8FAFF">
        <div class="raysync-function-list">
          <raysync-function
            image="file"
            title="文件分享与收发"
            content="支持群组空间与个人空间文件夹分享下载与上传功能，以外链接的方式发送至伙伴邮箱，与伙伴共享数据。"
          ></raysync-function>
          <raysync-function
            image="account"
            title="账户/账户组权限管理"
            content="管理员可创建多个账户，可对账户、账户组、部门设置文件或文件夹的操作权限，细分至单一文档作，实现灵活的权限管控，细化企业管理。"
          ></raysync-function>
          <raysync-function
            image="group"
            title="团队共享群组空间"
            content="按部门、团队或项目创建多个共享群组空间，每个空间独立权限控制，展开业务互不干扰。子账户私密空间和群组空间不混淆更专业。"
          ></raysync-function>
          <raysync-function
            image="synchronization"
            title="文件智能同步"
            content="文件自动同步，无需值守，本地文件与服务器端文件始终保持一致，告别手动拷贝，提升办公效率。"
          ></raysync-function>
          <raysync-function
            image="management"
            title="全局安全监控管理"
            content="实况监测在线传输，日志记录数据信息，企业的核心业务数据可视、可控、可追溯。"
          ></raysync-function>
          <raysync-function
            image="storage"
            title="存储模式灵活切换"
            content="可自由配置切换本地存储与第三方平台存储，满足多个分支数据统一管理需求"
          ></raysync-function>
          <raysync-function
            image="personal"
            title="个性化设置"
            content="支持自定义logo及背景图片，定制企业形象。"
          ></raysync-function>
          <raysync-function
            image="multiple"
            title="多平台支持"
            content="支持各大主流系统平台、浏览器，提供语言包切换功能"
          ></raysync-function>
        </div>
      </sub-content>
      <sub-content title="架构部署">
        <div id="structure-image"></div>
      </sub-content>
      <!-- <sub-content title="成功案例">
        <div class="raysync-success-list">
          <raysync-success
            image="huawei"
            title="华为"
            content="华为合作伙伴需要先从华为德国源视频存储中心下载视频，并对视频进行相应的转码编辑再处理，然后再将制作完成的文件传输到德国视频成品存储中心。 飞安达传输帮助华为德国的linux主机配置了飞安达服务端，在深圳中软的windows主机配置了飞安达web端，将深圳中软从德国另一个点下载下来的视频进行转码之后，通过飞安达传输到德国。"
          >
            • 轻松应对大文件传输难题，视频传输速度提升数百倍；<br />
            •
            远程，弱网环境也能够最大限度利用网络带宽，文件跨国传输不再成为阻碍；<br />
            • 快速集成于华为现有系统中，降低企业开发成本；<br />
            •
            全程SSL加密，无须担心核心产品研发、设计资料在传输过程中被第三方恶意窃取；
          </raysync-success>
          <raysync-success
            image="oppo"
            title="OPPO"
            content="随着全球业务的不断发展及壮大，OPPO急需解决广域网下的跨国数据传输问题，核心需求是希望在不改变原有系统架构的前提下，通过SDK直接集成。 飞安达传输为OPPO提供了全球主要分支机构访问总部应用系统网络加速功能方案，建设需求分为2部分：总部加速服务、分支机构加速客户端。飞安达为OPPO带来了如下改变："
          >
            •
            FTP应用/Web应用等的大数据传递速度最高提升100倍以上，单条连接最大速度支持1Gbps；<br />
            • 快速集成于OPPO现有系统当中，保留原有资料和使用习惯；<br />
            • 飞安达的多通道设计，让传输实时性提升不止1倍；<br />
            •
            最大限度利用网络带宽，在印度非洲等网络条件较差地区，速度都有了明显提升；
          </raysync-success>
          <raysync-success
            image="bgi"
            title="华大基因"
            content="华大基因通过遍布全球100多个国家和地区的分支机构，与产业链各方建立广泛的合作，将前沿的多组学科研成果应用于医学健康、资源保存、司法服务等领域。飞安达传输在华大基因数据归档库主服务器与备用服务器部署飞安达服务器，各研究机构与内部各团队部署飞安达网页端，通过飞安达自主研发的传输协议进行加速传输，实现数据快传交互。"
          >
            •
            飞安达核心加速传输技术，让华大基因研究成果传输更具时效性，让庞大的研究成果发挥价值<br />
            •
            飞安达拥有细致的操作权限，让不同研究机构人员拥有不同的操作权限，保证数据传输安全<br />
            • 飞安达执行有效检查以确保华大基因研究成果传输的完整性和准确性
          </raysync-success>
          <raysync-success
            image="nscg"
            title="广州超算"
            content="由广州超算中心计算出的数据需要分发至生物医疗健康行业、各大高校科研院、动漫渲染公司等。 飞安达传输在广州超算中心搭建服务器，在各合作伙伴处部署飞安达网页版，将需要进行精细化计算的数据传输至飞安达服务器，再通过飞安达网页版将计算完成后的数据下载回本地服务器。"
          >
            •
            飞安达轻松提升文件传输速度，将数据分析成果第一时间分发给各行业合作伙伴<br />
            •
            断点续传，多重校验，错误自动重传机制，保障数据传输质量，降低沟通成本<br />
            •
            支持自定义设置使用次数、有效期、使用时间等具体操作确保研究成果外发安全<br />
            • 自动将传输速度调节至最优状态，快速将超大文件传输到世界各地
          </raysync-success>
          <raysync-success
            image="gene"
            title="中科院北京基因组研究所"
            content="中国科学院北京基因组研究所基于研究产生的数据量异常庞大，数据的收发难题亟待解决。 在评估了多种加速文件传输选项后，中科院北京基因组研究所选择了飞安达传输。在基因组数据归档主服务器与备用服务器部署飞安达服务器，海内外各研究机构集成飞安达传输技术的网页端，实现跨国加速传输。"
          >
            •
            与传统的文件传输方法相比，飞安达核心传输技术加速了这些大型文件集的传输速度<br />
            •
            高效提升由于传统TCP传输导致的延迟、数据丢包等问题，解决研究所面临的传输数据不完整的问题<br />
            • 快速集成于企业现有系统中，降低企业开发成本<br />
            • 飞安达智能生成传输日志，方便研究所成员溯源
          </raysync-success>
          <raysync-success
            image="vivo"
            title="Vivo"
            content="VIVO是一家全球性的移动互联网智能终端公司，致力于为消费者打造拥有极致拍照、畅快游戏、Hi-Fi音乐的智能手机产品。除中国大陆市场外，VIVO进驻的海外市场包含印度、泰国、缅甸、马来西亚、印度尼西亚、越南和菲律宾。随着全球业务的开展，东莞总部与全球各地跨国远距离传输，常常遇到连接断线、丢包的现象。"
          >
            •
            依托飞安达断点续传，多重校验，错误自动重传机制，保证传输数据100%正确和完整<br />
            •
            使用飞安达后传输速度大幅提升，解决了因跨国传输网络条件恶劣带来的传输低效问题<br />
            • AES-256加密，并结合通道加密，为VIVO全球数据交换建立绿色安全通道<br />
            • 设置细致的操作权限，让相应的人员匹配相应的权限
          </raysync-success>
        </div>
      </sub-content> -->
    </div>
    <contact-us></contact-us>
    <bottom-info></bottom-info>
  </div>
</template>

<script>
import TopBar from "../components/TopBar.vue";
import Banner from "../components/Banner.vue";
import SubContent from "../components/SubContent.vue";
import RaysyncFeature from "./components/RaysyncFeature.vue";
import RaysyncFunction from "./components/RaysyncFunction.vue";
// import RaysyncSuccess from "./components/RaysyncSuccess.vue";
import ContactUs from "../components/ContactUs.vue";
import BottomInfo from "../components/BottomInfo.vue";
export default {
  name: "raysync",
  components: {
    TopBar,
    Banner,
    SubContent,
    RaysyncFeature,
    RaysyncFunction,
    // RaysyncSuccess,
    ContactUs,
    BottomInfo,
  },
  data() {
    return {
      nowActive: 0,
    };
  },
  methods: {
    setNowActive(val) {
      this.nowActive = val;
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/common/style/mixins.scss";
.raysync {
  .sub-content-list {
    .raysync-feature-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 87px;
      .raysync-feature-list {
        display: flex;
        width: 1150px;
        justify-content: space-between;
      }
      .raysync-info {
        width: 1158px;
        border: 1px solid #b5c7ee;
        display: flex;
        padding: 20px;
        align-items: center;
        .image-wrap {
          width: 734px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f5f7fd;
          padding: 40px 24px;
          .image {
            width: 681px;
          }
          #performance-content-image {
            height: 203px;
            background-image: url("../../assets/products/Raysync/performanceContent2.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            /* @include bg-image(
              "../../assets/products/Raysync/performanceContent"
            ); */
          }
          #safety-content-image {
            height: 224px;
            background-image: url("../../assets/products/Raysync/safetyContent2.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            /* @include bg-image("../../assets/products/Raysync/safetyContent"); */
          }
          #reliable-content-image {
            height: 441.2px;
            background-image: url("../../assets/products/Raysync/reliableContent2.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            /* @include bg-image("../../assets/products/Raysync/reliableContent"); */
          }
          #multiple-way-content-image {
            height: 400px;
            background-image: url("../../assets/products/Raysync/multipleWayContent2.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            /* @include bg-image(
              "../../assets/products/Raysync/multipleWayContent"
            ); */
          }
          #central-content-image {
            height: 310.2px;
            background-image: url("../../assets/products/Raysync/centralContent2.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            /* @include bg-image("../../assets/products/Raysync/centralContent"); */
          }
          #compatibility-content-image {
            height: 278.6px;
            background-image: url("../../assets/products/Raysync/compatibilityContent2.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            /* @include bg-image(
              "../../assets/products/Raysync/compatibilityContent"
            ); */
          }
        }
        .text {
          line-height: 22.4px;
          width: 365px;
          margin-left: 40px;
          font-weight: 400;
          color: #8796aa;
          font-size: 14px;
        }
      }
    }
    .raysync-function-list {
      display: flex;
      flex-wrap: wrap;
      width: 1127px;
      justify-content: space-between;
      margin-bottom: 32px;
    }
    .raysync-function-list::after {
      content: "";
      width: 305px;
    }
    .server-advantage-list {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 117px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
    #structure-image {
      width: 1200px;
      height: 636px;
      margin-bottom: 18px;
      /* @include bg-image("../../assets/products/Raysync/structure"); */
      background-image: url("../../assets/products/Raysync/structure2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
    .raysync-success-list {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 70px;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
      }
    }
  }
}
</style>